import { initCap } from "@/utils/strings";

export default (task: any) : string => {
  const stage = task.construction_stage;
  if (!stage) return "";

  if (stage === "super_structure" || stage === "sub_structure") {
    return initCap(stage.replace(/_/g, "-"));
  }

  if (stage === "na") {
    return "N/A";
  }

  return initCap(stage.replace(/_/g, " "));
}
