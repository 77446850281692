import { ICellRendererComp, ICellRendererParams } from "ag-grid-community";
import deleteIcon from "@images/icons/delete.svg";

export default class DeleteActionCellRenderer implements ICellRendererComp {
  declare gui: HTMLElement;
  declare params: any;
  declare icon: HTMLElement;

  init(params: any) {
    this.params = params;
    this.gui = this.createGui();
  }

  private createGui(): HTMLElement {
    const gui = document.createElement("a");
    gui.classList.add(
      "block",
      "cursor-pointer",
      "flex",
      "h-full",
      "items-center",
      "justify-center",
      "w-full"
    );
    gui.href = `/${this.params.resourceType}/${this.params.resourceId}`;
    gui.setAttribute("data-turbo-stream", "");
    gui.setAttribute("data-turbo-confirm", "Are you sure you want to delete this?");
    gui.setAttribute("data-turbo-method", "delete");

    // The button to add a comment
    this.icon = document.createElement("span");
    this.icon.classList.add("relative", "text-red-500");
    this.icon.innerHTML = deleteIcon;

    gui.append(this.icon);
    return gui;
  }

  getGui() {
    return this.gui;
  }

  refresh(_params: ICellRendererParams<any, any>): boolean {
    return true;
  }
}
