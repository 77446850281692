import { gantt } from "dhtmlx-gantt";

/**
 * Configures the dataprocessor for the gantt
 * chart. This is responsible for making requests
 * to the server whenever anything changes in the
 * gantt chart
 */
export default (baseUrl: string): void => {
  // @ts-ignore
  const dp = new gantt.dataProcessor(baseUrl);
  const csrfMeta: HTMLMetaElement | null = document.querySelector(
    'meta[name="csrf-token"]'
  );

  dp.init(gantt);
  dp.setTransactionMode({
    mode: "REST-JSON",
    headers: {
      "X-CSRF-Token": csrfMeta?.content,
    },
  });

  // When we send the data to the server, we need to wrap it
  // in a `task` key.
  dp.attachEvent("onBeforeDataSending", (id: any, state: any, data: any) => {
    delete data["!nativeeditor_status"];
    delete data.id;

    let taskData: { [index: string]: any } = {};
    for (let key in data) {
      taskData[key] = data[key];
      delete data[key];
    }

    data.task = taskData;

    return true;
  });
};
