import { Controller } from "@hotwired/stimulus";
import Quill from "quill";
import { JSONApiArrayResponse, TaggableUser } from "types";
import "quill-mention";

export default class extends Controller {
  static targets = ["container", "editor", "hidden"];

  declare readonly containerTarget: HTMLElement;
  declare readonly editorTarget: HTMLElement;
  declare readonly hiddenTarget: HTMLInputElement;

  connect() {
    const simple = this.containerTarget.dataset.simple === "true";
    const taggable = this.containerTarget.dataset.taggable === "true";
    const quill = new Quill(
      this.editorTarget, 
      this.generateQuillOptions(simple, taggable)
    );

    quill.on("text-change", () => {
      this.hiddenTarget.value = quill.root.innerHTML;
    });

    quill.on("selection-change", (range, oldRange, _source) => {
      if (range === null && oldRange !== null) {
        this.lostFocus();
      } else {
        this.gotFocus();
      }
    });
  }

  private generateTaggableOptions(taggable: boolean) {
    if (!taggable) return {};

    return {
      mention: {
        mentionDenotationChars: ["@"],
        dataAttributes: ["userId"],
        source: async (searchTerm: string, renderList: (arg0: any) => {}) => {
          const searchResults = await this.searchForUsers(searchTerm);
          renderList(searchResults);
        }
      }
    };
  }

  private generateQuillOptions(simple: boolean, taggable: boolean) {
    const simpleToolbar = ["bold", "italic", "underline", "strike"]

    return {
      theme: "snow",
      modules: {
        toolbar: simple ?
          [simpleToolbar, [{ list: "ordered" }, { list: "bullet" }]] : 
          [
            simpleToolbar,
            ["blockquote"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          ],
        ...this.generateTaggableOptions(taggable)
      },
    };
  }

  private gotFocus() {
    this.containerTarget.classList.add(
      "ring-2",
      "ring-blue-500",
      "outline-none",
      "border-blue-500"
    );
  }

  private lostFocus() {
    this.containerTarget.classList.remove("ring-blue-500");
    this.containerTarget.classList.remove("outline-none");
    this.containerTarget.classList.remove("ring-2");
    this.containerTarget.classList.remove("border-blue-500");
  }

  private async searchForUsers(searchTerm: string) {
    const formUrl = this.containerTarget.dataset.usersPath;
    const usersUrl = `${formUrl}?q=${searchTerm}`;

    const headers = new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    });

    const resp = await fetch(usersUrl, { headers });
    const json = (await resp.json()) as JSONApiArrayResponse<TaggableUser>;
    const users = json.data.map((data) => data.attributes);

    return users;
  }
}
