import UploadsCellRenderer from "@/components/cell_renderers/uploads_cell_renderer";
import BasicDatatableController from "./basic_datatable_controller";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import EditActionCellRenderer from "@/components/cell_renderers/edit_action_cell_renderer";
import { AppointmentAndWarrantyItem, JSONApiResponseObject } from "types";
import CommentsCellRenderer from "@/components/cell_renderers/comments_cell_renderer";
import BooleanCellRenderer from "@/components/cell_renderers/boolean_cell_renderer";

const cellRendererParamsFn = (params: ICellRendererParams) => {
  const line = params.data as AppointmentAndWarrantyItem;

  return {
    resourceType: "appointment_and_warranty_items",
    resourceId: line.id,
    uploadType: "appointment_warranty_document"
  };
};

export default class extends BasicDatatableController {
  columnDefs(): ColDef[] {
    return [
      { field: "attributes.professional", headerName: "Professional" },
      {
        field: "attributes.applicable",
        headerName: "Applicable",
        flex: 1,
        cellRenderer: BooleanCellRenderer
      },
      { field: "attributes.contractualParty", headerName: "Service" },
      { field: "attributes.appointmentReceived", headerName: "Appointment Received" }, 
      { field: "attributes.collateralWarrantyExecuted", headerName: "Collateral Warranty Executed" },
      ...(
        this.readonly
          ? []
          : [
            {
              pinned: "right",
              width: 50,
              cellRenderer: EditActionCellRenderer,
              cellRendererParams: cellRendererParamsFn,
              resizable: false
            },
            {
              field: "attributes.uploadsCount",
              headerName: "",
              pinned: "right",
              width: 50,
              cellRenderer: UploadsCellRenderer,
              cellRendererParams: cellRendererParamsFn,
              resizable: false
            }
          ] as ColDef[]
      ),
      {
        field: "attributes.commentsCount",
        headerName: "",
        pinned: "right",
        width: 50,
        cellRenderer: CommentsCellRenderer,
        cellRendererParams: cellRendererParamsFn,
        resizable: false
      },

    ];
  }

  public itemAdded(e: CustomEvent) {
    const response = e.detail as JSONApiResponseObject<AppointmentAndWarrantyItem>;
    const newRow = response.data;
    this.addRows(newRow);
  }

  public itemRemoved(e: CustomEvent) {
    const response = e.detail;
    this.removeRows(response);
  }

  public itemUpdated(e: CustomEvent) {
    const response = e.detail as JSONApiResponseObject<AppointmentAndWarrantyItem>;
    const updatedRow = response.data;

    this.updateRows(updatedRow);
  }
}
