import { ICellRendererComp, ICellRendererParams } from "ag-grid-community";

export default class CurrencyCellRenderer implements ICellRendererComp {
  declare formatter: Intl.NumberFormat;
  declare gui: HTMLElement;
  declare params: any;

  constructor() {
    this.formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    });
  }

  init(params: any) {
    this.params = params;
    this.gui = this.createGui();
  }

  private createGui(): HTMLElement {
    const gui = document.createElement("div");
    gui.textContent = this.formatter.format(this.params.value);

    if (this.params.value < 0) {
      gui.classList.add("text-red-600");
    }

    return gui;
  }

  getGui() {
    return this.gui;
  }

  refresh(params: ICellRendererParams) {
    this.params.value = params.value;
    this.gui.textContent = this.formatter.format(this.params.value);

    return true;
  }
}
