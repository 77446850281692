import { StreamActions } from "@hotwired/turbo";

StreamActions.add_table_row = function() {
	const record = JSON.parse(this.getAttribute("record") || "");
	const event = new CustomEvent("row-added", {
		detail: record
	});

	this.targetElements.forEach((el: HTMLElement) => {
		el.dispatchEvent(event);
	});
}
