import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

const ANIMATION_DURATION: number = 300;

export default class extends Controller {
  static targets = ["input", "evidenceWrapper", "evidence"];

  declare readonly inputTarget: HTMLInputElement;
  declare readonly evidenceTarget: HTMLDivElement;
  declare readonly evidenceWrapperTarget: HTMLDivElement;

  declare changeCallback: (e: Event) => void;

  evidenceVisible: boolean = false;

  connect(): void {
    this.changeCallback = this.onChange.bind(this);
    this.inputTarget.addEventListener("change", this.changeCallback);
  }

  disconnect(): void {
    this.inputTarget.removeEventListener("change", this.changeCallback);
  }

  private hideEvidenceInputs(): void {
    this.setEvidenceWrapperHeight();

    requestAnimationFrame(() => {
      this.evidenceWrapperTarget.style.height = "0px";
      setTimeout(() => {
        this.evidenceWrapperTarget.classList.add("hidden");
      }, ANIMATION_DURATION);
    });

    this.evidenceVisible = false;
  }

  private onChange(e: Event): void {
    const value = this.inputTarget.value;
    const floatValue = parseFloat(value);

    if (floatValue === 0 || Number.isNaN(floatValue)) {
      // Hide
      this.hideEvidenceInputs();
    } else if (!this.evidenceVisible) {
      // Show
      this.showEvidenceInputs();
    }
  }

  private showEvidenceInputs(): void {
    if (this.evidenceWrapperTarget.classList.contains("hidden")) {
      this.evidenceWrapperTarget.style.height = "0px";
      this.evidenceWrapperTarget.classList.remove("hidden");

      requestAnimationFrame(() => {
        this.setEvidenceWrapperHeight();
        setTimeout(() => {
          this.evidenceWrapperTarget.style.height = `auto`;
        }, ANIMATION_DURATION);
      });
    }

    this.evidenceVisible = true;
  }

  private setEvidenceWrapperHeight(): void {
    const contentHeight = this.evidenceTarget.offsetHeight;
    this.evidenceWrapperTarget.style.height = `${contentHeight}px`;
  }
}
