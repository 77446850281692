import { ActionEvent, Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modalTemplate", "file", "lightbox"];

  declare readonly fileTargets: HTMLElement;
  declare readonly modalTemplateTarget: HTMLTemplateElement;
  declare readonly lightboxTarget: HTMLElement;

  declare downloadUrl: string;

  public closePreview(e: ActionEvent): void {
    this.lightboxTarget.remove();
  }
  
  public download(e: ActionEvent): void {
    window.location.href = this.downloadUrl;
  }

  public openPreview(e: ActionEvent) {
    const { contentType, fileUrl, fileDownloadUrl, representable } = e.params;
    const template = this.modalTemplateTarget.content.cloneNode(true) as HTMLElement;
    const contentContainer = template.querySelector(".lightbox__content");
    if (!contentContainer) return;

    this.downloadUrl = fileDownloadUrl;

    if (representable) {
      const content = this.generateContent(contentType, fileUrl);
      if (content) {
        contentContainer.innerHTML = "";
        contentContainer.appendChild(content);
      }
    } else {
    }

    this.element.appendChild(template);
  }

  private generateContent(contentType: string, fileUrl: string): HTMLElement | null {
    if (contentType.match(/image\/.*/)) {
      const imgTag = document.createElement("img");
      imgTag.setAttribute("src", fileUrl);
      imgTag.classList.add("object-cover");

      return imgTag;
    }

    return null;
  }
}
