import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets: string[] = ["additionalInfo", "option"];

  declare selectedValue: string;
  declare readonly additionalInfoTarget: HTMLElement;
  declare readonly optionTargets: HTMLInputElement[];

  connect() {
    const checkedOption = this.optionTargets.filter(opt => opt.checked);
    this.selectedValue = checkedOption[0]?.value;
    this.toggleAdditionalInfo();
  }

  public ragStatusChanged(e: CustomEvent): void {
    const radio = e.target as HTMLInputElement;
    this.selectedValue = radio.value;
    this.toggleAdditionalInfo();
  }

  private toggleAdditionalInfo() : void {
    if (this.selectedValue === "green") {
      this.additionalInfoTarget.classList.add("hidden");
    } else {
      this.additionalInfoTarget.classList.remove("hidden");
    }
  }
}
