import { ICellRendererComp, ICellRendererParams } from "ag-grid-community";
import fileIcon from "@images/icons/file.svg";

export default class UploadsCellRenderer implements ICellRendererComp {
  declare gui: HTMLElement;
  declare params: any;
  declare uploadsCount: number;
  declare icon: HTMLElement;

  init(params: any) {
    this.uploadsCount = params.value;
    this.params = params;
    this.gui = this.createGui();
  }

  private createGui(): HTMLElement {
    const gui = document.createElement("a");
    gui.classList.add(
      "block",
      "cursor-pointer",
      "flex",
      "h-full",
      "items-center",
      "justify-center",
      "w-full"
    );

    const uploadType = this.params.uploadType;
    const urlParams = uploadType ? `?upload_type=${uploadType}` : "";
    gui.href = `/${this.params.resourceType}/${this.params.resourceId}/uploads${urlParams}`;
    gui.setAttribute("data-turbo-stream", "");

    // The button to add a comment
    this.icon = document.createElement("span");
    this.icon.classList.add("relative", "text-gray-500");
    this.icon.innerHTML = fileIcon;

    this.icon.classList.add(
      "before:absolute",
      "before:bg-blue-500",
      "before:content-[attr(data-uploads)]",
      "before:min-w-[1rem]",
      "before:px-1",
      "before:right-0",
      "before:rounded-full",
      "before:text-center",
      "before:text-white",
      "before:translate-x-1/4",
      "before:-translate-y-1/4",
      "text-xs"
    );

    if (this.uploadsCount > 0) {
      this.icon.setAttribute("data-uploads", this.uploadsCount.toString());
    } 

    gui.append(this.icon);
    return gui;
  }

  getGui() {
    return this.gui;
  }

  refresh(params: ICellRendererParams) {
    this.uploadsCount = params.value;

    if (this.uploadsCount > 0) {
      this.icon.setAttribute("data-uploads", this.uploadsCount.toString());
    } else {
      this.icon.removeAttribute("data-uploads");
    }

    return true;
  }
}
