import BasicDatatableController from "./basic_datatable_controller";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import CurrencyCellRenderer from "@/components/cell_renderers/currency_cell_renderer";
import EditActionCellRenderer from "@/components/cell_renderers/edit_action_cell_renderer";
import { Property } from "types";

const cellRendererParamsFn = (params: ICellRendererParams) => {
  const property = params.data as Property;

  return {
    resourceType: "properties",
    resourceId: property.id,
  };
};

export default class extends BasicDatatableController {
  columnDefs(): ColDef[] {
    return [
      { field: "attributes.name", headerName: "Name", flex: 1 },
      { field: "attributes.propertyCode", headerName: "Property code" },
      { field: "attributes.budget", headerName: "Budget", cellRenderer: CurrencyCellRenderer },
      { field: "attributes.duration", headerName: "Duration" },
      { field: "attributes.developer", headerName: "Developer" },
      {
        pinned: "right",
        width: 50,
        cellRenderer: EditActionCellRenderer,
        cellRendererParams: cellRendererParamsFn,
        resizable: false
      },
    ];
  } 
}
