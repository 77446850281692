import ActionsCellRenderer from "@/components/cell_renderers/actions_cell_renderer";
import BasicDatatableController from "./basic_datatable_controller";
import { DropdownMenuItem } from "types";
import { formatDatetime, dateComparator } from "@/utils/dates";
import { ColDef, GridOptions } from "ag-grid-community";
import { destroy } from "@rails/request.js";
import RAGStatusCellRenderer from "@/components/cell_renderers/rag_status_cell_renderer";

export default class extends BasicDatatableController {
  columnDefs(): ColDef[] {
    const _this = this;

    return [
      { field: "attributes.lender", headerName: "Lender", sortable: true },
      {
        field: "attributes.name",
        minWidth: 200,
        headerName: "Loan",
        flex: 1,
        cellRenderer(params: any) {
          return `<a href="/loans/${params.data.id}" class="link">${params.value}</a>`;
        },
        sortable: true
      },
      { field: "attributes.loanCode", headerName: "Loan code", sortable: true },
      { field: "attributes.ragStatus", headerName: "RAG", cellRenderer: RAGStatusCellRenderer, sortable: true },
      { field: "attributes.property", headerName: "Property", sortable: true },
      { field: "attributes.weeks", headerName: "Weeks", sortable: true },
      {
        field: "attributes.updatedAt",
        headerName: "Last updated",
        cellRenderer(params: any) {
          return formatDatetime(params.value);
        },
        sortable: true,
        comparator: dateComparator 
      },
      { field: "attributes.externalQs", headerName: "External QS", sortable: true },
      {
        colId: "actions",
        headerName: "Actions",
        width: 100,
        cellRenderer: ActionsCellRenderer,
        cellRendererParams: {
          label: "Test",
          menuItems: <DropdownMenuItem[]>[
            {
              label: "Edit",
              href(row: any) {
                return `/loans/${row.id}/edit`;
              },
            },
            {
              label: "Delete",
            },
          ],
          onClick(row: any, menuItem: DropdownMenuItem, e: Event) {
            _this.actionClicked(row, menuItem, e);
          },
        },
      },
    ];
  }

  gridOptions(): GridOptions {
    return {
      onCellClicked(params) {
        if (params.column.getColId() === "actions") {
          const cellRendererInstances = params.api.getCellRendererInstances({
            rowNodes: [params.node],
            columns: [params.column],
          });

          if (cellRendererInstances.length > 0) {
            const instance = <ActionsCellRenderer>cellRendererInstances[0];
            instance.togglePopup();
          }
        }
      },
    };
  }

  private actionClicked(row: any, menuItem: DropdownMenuItem, e: Event): void {
    if (menuItem.label === "Delete") {
      e.preventDefault();
      this.deleteLoan(row);
    }
  }

  private async deleteLoan(row: any): Promise<void> {
    const confirmed = confirm("Are you sure you want to delete this loan?");
    if (!confirmed) return;

    const response = await destroy(`/loans/${row.id}`);
    if (response.ok) {
      this.removeRows(row);
    }
  }
}
