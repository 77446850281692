import { Controller } from "@hotwired/stimulus";
import { gantt } from "dhtmlx-gantt";
import configureDataProcessor from "./construction_programme/dataprocessor";
import configureGantt from "./construction_programme/configure";
import configureLightbox from "./construction_programme/lightboxes";
import configureRenderers from "./construction_programme/renderers";
import configureTemplates from "./construction_programme/templates";
import { GanttTask, GanttBuilding } from "types";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["gantt"];

  declare readonly ganttTarget: HTMLElement;
  declare dataUrl: string;
  declare apiUrl: string;

  connect() {
    this.dataUrl = this.ganttTarget.dataset.dataUrl || "";
    this.apiUrl = this.ganttTarget.dataset.apiUrl || "";

    configureGantt();
    configureTemplates();
    configureRenderers();
    configureLightbox();

    gantt.config.readonly = (<HTMLElement>this.element).dataset.readonly === "true";

    this.initGantt();
    configureDataProcessor(this.apiUrl);
  }

  private initGantt() {
    gantt.init(this.ganttTarget);
    gantt.load(this.dataUrl);
  }

  /**
   * Adds a new building to the gantt. This will be created
   * at the root level
   */
  addBuilding(): void {
    gantt.addTask({
      text: "New building",
      type: gantt.config.types.project,
    });
  }

  /**
   * Adds a new task to the gantt. This will be added into
   * the currently selected building, or the root if no
   * building is selected, or if another task is selected
   */
  addTask(): void {
    const currentTask = this.getCurrentTask();
    let parent = null;

    if (currentTask) {
      parent =
        currentTask.type == gantt.config.types.project ? currentTask.id : null;
    }

    gantt.addTask({
      duration: 1,
      parent,
      rag_status: "green",
      start_date: new Date(),
      status: "unstarted",
      text: "New task",
      type: gantt.config.types.task,
    });
  }
  
  private getCurrentTask() : GanttTask | GanttBuilding | null {
    const currentTaskId = gantt.getSelectedId();
    if (!currentTaskId) return null;

    return gantt.getTask(currentTaskId) as GanttTask | GanttBuilding;
  }

  /**
   * Opens the commenting sidebar for the selected task/building
   **/ 
  public async comment() : Promise<void> {
    const currentTask = this.getCurrentTask();
    if (!currentTask) return;

    let taskTypePath = "tasks";

    if (currentTask?.type === "project") { taskTypePath = "buildings" }

    await get(`/${taskTypePath}/${currentTask.id}/comments`, {
      responseKind: "turbo-stream",
    });
  }

  /**
   * Zooms the gantt chart in
   **/
  public zoomIn() : void {
    gantt.ext.zoom.zoomIn();
  }

  /**
   * Zooms the gantt chart out
   **/
  public zoomOut() : void {
    gantt.ext.zoom.zoomOut();
  }

  /**
   * Destroy selected building
   **/
  public async destroyBuilding() : Promise<void> {
    const currentTask = this.getCurrentTask();
    if (!currentTask) return;

    if (currentTask?.type === "project"){
      await get(`/buildings/${currentTask.id}/discard_building`, {
        responseKind: "turbo-stream"
      });
      gantt.deleteTask(currentTask.id);
    }else{
      return;
    }
  }

}
