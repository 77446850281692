import BasicDatatableController from "./basic_datatable_controller";
import CurrencyCellRenderer from "@/components/cell_renderers/currency_cell_renderer";
import { ColDef, GridOptions, ICellRendererParams } from "ag-grid-community";
import CommentsCellRenderer from "@/components/cell_renderers/comments_cell_renderer";
import UploadsCellRenderer from "@/components/cell_renderers/uploads_cell_renderer";
import {
  JSONApiObject,
  JSONApiResponseObject,
  StatutoryAnalysisCondition,
} from "types";
import DeleteActionCellRenderer from "@/components/cell_renderers/delete_action_cell_renderer";
import EditActionCellRenderer from "@/components/cell_renderers/edit_action_cell_renderer";

const cellRendererParamsFn = (params: ICellRendererParams) => {
  const line = params.data as StatutoryAnalysisCondition;

  return {
    resourceType: "statutory_analysis_conditions",
    resourceId: line.id,
    uploadType: "condition_document"
  };
};

export default class extends BasicDatatableController {
  columnDefs(): ColDef[] {
    return [
      {
        headerName: "No.",
        valueGetter: "node.rowIndex + 1",
        width: 75,
        resizable: false,
      },
      {
        field: "attributes.description",
        headerName: "Description",
        flex: 1,
      },
      {
        field: "attributes.dischargeStatus",
        headerName: "Discharge status",
      },
      {
        field: "attributes.expectedDate",
        headerName: "Expected date",
      },
      {
        field: "attributes.requiredCompletionDate",
        headerName: "Required completion date",
      },
      {
        cellRenderer: EditActionCellRenderer,
        headerName: "",
        pinned: "right",
        resizable: false,
        width: 50,
        cellRendererParams: cellRendererParamsFn,
      },
      {
        field: "attributes.uploadsCount",
        headerName: "",
        pinned: "right",
        width: 50,
        cellRenderer: UploadsCellRenderer,
        cellRendererParams: cellRendererParamsFn,
        resizable: false
      },
      {
        cellRenderer: CommentsCellRenderer,
        field: "attributes.commentsCount",
        headerName: "",
        pinned: "right",
        resizable: false,
        width: 50,
        cellRendererParams: cellRendererParamsFn,
      },
      {
        cellRenderer: DeleteActionCellRenderer,
        headerName: "",
        pinned: "right",
        resizable: false,
        width: 50,
        cellRendererParams: cellRendererParamsFn,
      },
    ];
  }

  public conditionAdded(e: CustomEvent) {
    const response =
      e.detail as JSONApiResponseObject<StatutoryAnalysisCondition>;
    const newRow = response.data;
    this.addRows(newRow);
  }

  public conditionRemoved(e: CustomEvent) {
    const response = e.detail;
    this.removeRows(response);
  }

  public conditionUpdated(e: CustomEvent) {
    const response =
      e.detail as JSONApiResponseObject<StatutoryAnalysisCondition>;
    const updatedRow = response.data;

    this.updateRows(updatedRow);
  }

  protected gridOptions(): GridOptions<any> {
    return {
      defaultColDef: { resizable: true },
      domLayout: "autoHeight",
      rowHeight: 42,
    };
  }
}
