const subScaleLabel = (label: string, value: string) : string => {
  return `
  <span class="font-normal">
  <span class="text-gray-400">${label}</span>
  ${value}
  </span>`;
}

const dayScale = {
  unit: "day",
  step: 1,
  format(date: Date): string {
    // A single digit day number
    const dayNumber = gantt.date.date_to_str("%j");
    // Appreviated day name
    const dayName = gantt.date.date_to_str("%D");

    return subScaleLabel(
      dayName(date)[0],
      dayNumber(date)
    );
  }
};

const zoomConfig = {
  levels: [
    {
      name: "day",
      scale_height: 67,
      min_column_width: 42,
      scales: [
        { unit: "month", step: 1, format: "%M %Y" },
        dayScale
      ]
    },
    {
      name:"month",
      scale_height: 67,
      min_column_width: 70,
      scales:[
        {unit: "month", format: "%F, %Y"},
        {
          unit: "week",
          format(date: Date) : string {
            const weekNumber = gantt.date.date_to_str("%W")
            return subScaleLabel(
              "Week",
              weekNumber(date)
            )
          }
        }
      ]
    },
    {
      name:"quarter",
      scale_height: 67,
      min_column_width:90,
      scales:[
        {unit: "month", step: 1, format: "%M"},
        {
          unit: "quarter", step: 1, format: function (date: Date) {
            var dateToStr = gantt.date.date_to_str("%M");
            var endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
            var year = gantt.date.date_to_str("%Y");
            return `${dateToStr(date)} - ${dateToStr(endDate)} ${year(endDate)}`;
          }
        }
      ]},
    {
      name:"year",
      scale_height: 67,
      min_column_width: 60,
      scales:[
        {unit: "year", step: 1, format: "%Y"}
      ]}
  ]
};

export default () : void => {
  gantt.ext.zoom.init(zoomConfig);
}
