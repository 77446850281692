import { Context, Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "tabContentPanel"];

  declare activeTab: HTMLElement;

  readonly activeClass: string = "tabs__tab--active";
  readonly hiddenTabPanelClass: string = "hidden";

  declare readonly tabTargets: HTMLElement[];
  declare readonly tabContentPanelTargets: HTMLElement[];

  connect() {
    this.activeTab = this.tabTargets[0];
    this.dispatch("tabChanged", {
      detail: { tab: this.activeTab.dataset.tab },
    });
  }

  changeTab(e: Event) {
    const target = e.target as HTMLElement;
    if (!target) return;

    const targetTab = target.dataset.tab;

    this.activeTab.classList.remove(this.activeClass);
    target.classList.add(this.activeClass);

    this.tabContentPanelTargets.forEach((tab: HTMLElement) => {
      if (tab.dataset.tab === targetTab) {
        tab.classList.remove(this.hiddenTabPanelClass);
      } else {
        tab.classList.add(this.hiddenTabPanelClass);
      }
    });

    this.dispatch("tabChanged", { detail: { tab: targetTab } });

    this.activeTab = target;
  }
}
