import BasicDatatableController from "./basic_datatable_controller";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import EditActionCellRenderer from "@/components/cell_renderers/edit_action_cell_renderer";
import DeleteActionCellRenderer from "@/components/cell_renderers/delete_action_cell_renderer";
import {  JSONApiResponseObject, User } from "types";
import UserNameAndAvatarCellRenderer from "@/components/cell_renderers/user_name_and_avatar_cell_renderer";

const cellRendererParamsFn = (params: ICellRendererParams) => {
  const line = params.data as User;

  return {
    resourceType: "users",
    resourceId: line.id,
  };
};

export default class extends BasicDatatableController {
  columnDefs(): ColDef[] {
    return [
      { field: "attributes", headerName: "Name", flex: 1, cellRenderer: UserNameAndAvatarCellRenderer },
      { field: "attributes.role", headerName: "Role" },
      { field: "attributes.status", headerName: "Status" },
      {
        pinned: "right",
        width: 50,
        cellRenderer: EditActionCellRenderer,
        cellRendererParams: cellRendererParamsFn,
        resizable: false
      },
      {
        cellRenderer: DeleteActionCellRenderer,
        headerName: "",
        pinned: "right",
        resizable: false,
        width: 50,
        cellRendererParams: cellRendererParamsFn,
      },
    ];
  }

  public userInvited(e: CustomEvent): void {
    const response = e.detail as JSONApiResponseObject<User>;
    const newRow = response.data;
    this.addRows(newRow);
  }

  public userRemoved(e: CustomEvent): void {
    const response = e.detail;
    this.removeRows(response);
  }

  public userUpdated(e: CustomEvent): void {
    const response = e.detail as JSONApiResponseObject<User>;
    const updatedRow = response.data;

    this.updateRows(updatedRow);
  }
}
