import { gantt } from "dhtmlx-gantt";

/**
 * Overrides the default behaviour of the gantt chart when
 * opening a lightbox. Instead of opening the default gantt
 * lightbox, this will send a DOM event which is picked up
 * by the lightbox controller to open a different lightbox
 * depending on the task type.
 */
export default (): void => {
  gantt.showLightbox = (id: string): void => {
    const task = gantt.getTask(id);

    const evt = new CustomEvent(`gantt-${task.type}-lightbox:open`, {
      detail: {
        task,
      },
    });
    window.dispatchEvent(evt);
  };
};
