/**
 * Converts the progress of a task into a more readable
 * percentage label
 */
export default (task: any): string => {
  const { progress } = task;
  const percentage = progress * 100;

  return `${Math.floor(percentage)}%`;
};
