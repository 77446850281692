import BasicDatatableController from "./basic_datatable_controller";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import EditActionCellRenderer from "@/components/cell_renderers/edit_action_cell_renderer";
import DeleteActionCellRenderer from "@/components/cell_renderers/delete_action_cell_renderer";
import {  Company, JSONApiResponseObject } from "types";

const cellRendererParamsFn = (params: ICellRendererParams) => {
  const company = params.data as Company;

  return {
    resourceType: "companies",
    resourceId: company.id,
  };
};

export default class extends BasicDatatableController {
  columnDefs(): ColDef[] {
    return [
      { field: "attributes.name", headerName: "Company name", flex: 1 },
      { field: "attributes.companyType", headerName: "Type of company", flex: 1 },
      {
        pinned: "right",
        width: 50,
        cellRenderer: EditActionCellRenderer,
        cellRendererParams: cellRendererParamsFn,
        resizable: false
      },
      {
        cellRenderer: DeleteActionCellRenderer,
        headerName: "",
        pinned: "right",
        resizable: false,
        width: 50,
        cellRendererParams: cellRendererParamsFn,
      },
    ];
  }

  public companyAdded(e: CustomEvent): void {
    const response = e.detail as JSONApiResponseObject<Company>;
    const newRow = response.data;
    this.addRows(newRow);
  }

  public companyRemoved(e: CustomEvent): void {
    const response = e.detail;
    this.removeRows(response);
  }

  public companyUpdated(e: CustomEvent): void {
    const response = e.detail as JSONApiResponseObject<Company>;
    const updatedRow = response.data;

    this.updateRows(updatedRow);
  }
}
