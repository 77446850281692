import { gantt } from "dhtmlx-gantt";

export default (): void => {
  gantt.config.type_renderers[gantt.config.types.project] = (
    task: any,
    _defaultRenderer: any
  ): HTMLElement => {
    const el = document.createElement("div");
    // @ts-ignore
    const size = gantt.getTaskPosition(task);

    el.setAttribute(gantt.config.task_attribute, task.id);

    const height = 7;
    const top = size.top + gantt.config.row_height / 2 - height / 2;

    el.classList.add("gantt_task_line", "building");
    el.style.left = `${size.left}px`;
    el.style.top = `${top}px`;
    el.style.width = `${size.width}px`;
    el.style.height = `${height}px`;

    return el;
  };
};
