import InsurancePoliciesBaseDatatableController from "./insurance_policies_base_datatable_controller";
import { ColDef, ICellRendererParams, ValueGetterParams } from "ag-grid-community";
import CommentsCellRenderer from "@/components/cell_renderers/comments_cell_renderer";
import { InsurancePolicy, JSONApiObject} from "types";
import CurrencyCellRenderer from "@/components/cell_renderers/currency_cell_renderer";
import UploadsCellRenderer from "@/components/cell_renderers/uploads_cell_renderer";
import EditActionCellRenderer from "@/components/cell_renderers/edit_action_cell_renderer";
import DeleteActionCellRenderer from "@/components/cell_renderers/delete_action_cell_renderer";

const cellRendererParamsFn = (params: ICellRendererParams) => {
  const line = params.data as InsurancePolicy;

  return {
    resourceType: "insurance_policies",
    resourceId: line.id,
    uploadType: "insurance_policy"
  };
};

export default class extends InsurancePoliciesBaseDatatableController {
  columnDefs(): ColDef[] {
    return [
      { field: "attributes.policyType", headerName: "Insurance" },
      { 
        field: "attributes.required",
        headerName: "Required", 
        valueGetter(node: ValueGetterParams<JSONApiObject<InsurancePolicy>>) { 
          const required = node.data?.attributes?.required;
          return required ? "Yes" : "No";
        }
      },
      {
        field: "attributes.value",
        headerName: "Value",
        cellRenderer: CurrencyCellRenderer,
      },
      { field: "attributes.basis", headerName: "Basis" },
      { field: "attributes.expiryDate", headerName: "Expiry date" },
      {
        field: "attributes.renewalDetails",
        headerName: "Renewal / Details required within 1 month",
      }, 
      ...(
        this.readonly
          ? []
          : [
            {
              pinned: "right",
              width: 50,
              cellRenderer: EditActionCellRenderer,
              cellRendererParams: cellRendererParamsFn,
              resizable: false
            },
            {
              field: "attributes.uploadsCount",
              headerName: "",
              pinned: "right",
              width: 50,
              cellRenderer: UploadsCellRenderer,
              cellRendererParams: cellRendererParamsFn,
              resizable: false
            }
          ] as ColDef[]
      ),
      {
        field: "attributes.commentsCount",
        headerName: "",
        pinned: "right",
        width: 50,
        cellRenderer: CommentsCellRenderer,
        cellRendererParams: cellRendererParamsFn,
        resizable: false
      },
      ...(
        this.readonly ? [] : [
          {
            pinned: "right",
            width: 50,
            cellRenderer: DeleteActionCellRenderer,
            cellRendererParams: cellRendererParamsFn,
            resizable: false
          },
        ] as ColDef[]
      )
    ];
  }
}
