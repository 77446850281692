import BasicDatatableController from "./basic_datatable_controller";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import DeleteActionCellRenderer from "@/components/cell_renderers/delete_action_cell_renderer";
import {  Company, JSONApiResponseObject } from "types";

const cellRendererParamsFn = (params: ICellRendererParams) => {
  const line = params.data as Company;

  return {
    resourceType: "quantity_surveyors",
    resourceId: line.id,
  };
};

export default class extends BasicDatatableController {
  columnDefs(): ColDef[] {
    return [
      { field: "attributes.name", headerName: "Quantity surveyor name", flex: 1 },
      {
        cellRenderer: DeleteActionCellRenderer,
        headerName: "",
        pinned: "right",
        resizable: false,
        width: 50,
        cellRendererParams: cellRendererParamsFn,
      },
    ];
  }

  public quantitySurveyorAdded(e: CustomEvent): void {
    const response = e.detail as JSONApiResponseObject<Company>;
    const newRow = response.data;
    this.addRows(newRow);
  }

  public quantitySurveyorRemoved(e: CustomEvent): void {
    const response = e.detail;
    this.removeRows(response);
  }

  public quantitySurveyorUpdated(e: CustomEvent): void {
    const response = e.detail as JSONApiResponseObject<Company>;
    const updatedRow = response.data;

    this.updateRows(updatedRow);
  }
}
