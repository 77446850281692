import { initCap } from "@/utils/strings";
import { GanttTask } from "types";

const CLASSES : { [index: string]: string } = {
  RED: "bg-red-50 text-red-700 ring-red-600/10",
  AMBER: "bg-yellow-50 text-yellow-800 ring-yellow-600/20",
  GREEN: "bg-green-50 text-green-700 ring-green-600/20"
};

/**
 * Generates the html for displaying a tasks
 * RAG status correctly in the gantt data grid
 */
export default (task: GanttTask): string => {
  const ragStatus = task.rag_status?.toUpperCase();

  if (!ragStatus) return "";
  if (!CLASSES[ragStatus]) return "";

  const classes = CLASSES[ragStatus];
  const label = initCap(ragStatus);
  return `<span class="rounded-full px-2 text-sm ring-1 ${classes}">${label}</span>`;
};
