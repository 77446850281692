import BasicDatatableController from "./basic_datatable_controller";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import EditActionCellRenderer from "@/components/cell_renderers/edit_action_cell_renderer";
import DeleteActionCellRenderer from "@/components/cell_renderers/delete_action_cell_renderer";
import {  Contract, JSONApiResponseObject } from "types";
import BooleanCellRenderer from "@/components/cell_renderers/boolean_cell_renderer";
import UploadsCellRenderer from "@/components/cell_renderers/uploads_cell_renderer";
import { initCap } from "@/utils/strings";

const cellRendererParamsFn = (params: ICellRendererParams) => {
  const line = params.data as Contract;

  return {
    resourceType: "contracts",
    resourceId: line.id,
    uploadType: "contract"
  };
};

export default class extends BasicDatatableController {
  columnDefs(): ColDef[] {
    return [
      {
        field: "attributes.contractType",
        headerName: "Contract Type",
        cellRenderer(params: any) {
          if (params.value !== "other"){
            return params.value.toUpperCase();
          }else{
            return initCap(params.value);
          }
        },
        flex: 1
      },
      {
        field: "attributes.executedAndSigned",
        headerName: "Executed and Signed",
        cellRenderer: BooleanCellRenderer
      },
      { field: "attributes.dated", headerName: "Dated" },
      { field: "attributes.underhandOrDeed", headerName: "Underhand/Deed", cellRenderer: (params: ICellRendererParams) => initCap(params.value) }, 
      ...(
        this.readonly
          ? []
          : [
            {
              pinned: "right",
              width: 50,
              cellRenderer: EditActionCellRenderer,
              cellRendererParams: cellRendererParamsFn,
              resizable: false
            },
            {
              field: "attributes.uploadsCount",
              headerName: "",
              pinned: "right",
              width: 50,
              cellRenderer: UploadsCellRenderer,
              cellRendererParams: cellRendererParamsFn,
              resizable: false
            },
            {
              cellRenderer: DeleteActionCellRenderer,
              headerName: "",
              pinned: "right",
              resizable: false,
              width: 50,
              cellRendererParams: cellRendererParamsFn,
            },
          ] as ColDef[]
      ),
    ];
  }

  public contractAdded(e: CustomEvent): void {
    const response = e.detail as JSONApiResponseObject<Contract>;
    const newRow = response.data;
    this.addRows(newRow);
  }

  public contractRemoved(e: CustomEvent): void {
    const response = e.detail;
    this.removeRows(response);
  }

  public contractUpdated(e: CustomEvent): void {
    const response = e.detail as JSONApiResponseObject<Contract>;
    const updatedRow = response.data;

    this.updateRows(updatedRow);
  }
}
