import { gantt } from "dhtmlx-gantt";
import columns from "./columns";
import layout from "./layout";
import configureRowDragging from "./interactions/row_dragging";
import configureZoom from "./interactions/zoom";

const adjustScale = () => {
  const range = gantt.getSubtaskDates();
  const scaleUnit = gantt.getState().scale_unit;

  let scaleAmount = 1;
  let yearCount = 5;

  if (scaleUnit === "year") {
    scaleAmount = yearCount;
  } else if (scaleUnit === "month") {
    scaleAmount = yearCount * 12;
  } else if (scaleUnit === "week") {
    scaleAmount = yearCount * 52;
  } else if (scaleUnit === "day") {
    scaleAmount = yearCount * 365;
  }

  if (range.start_date && range.end_date) {
    gantt.config.start_date = gantt.calculateEndDate(range.start_date, -scaleAmount, scaleUnit);
    gantt.config.end_date = gantt.calculateEndDate(range.end_date, scaleAmount, scaleUnit);
  }
}

/**
 * Configures the DHTMLX gantt chart library
 */
export default (): void => {
  gantt.config.autoscroll = true;
  gantt.config.bar_height = 21;
  gantt.config.columns = columns;
  gantt.config.date_format = "%Y-%m-%d %H:%i:%s";
  gantt.config.layout = layout;
  gantt.config.order_branch = "marker";
  gantt.config.order_branch_free = true;
  gantt.config.row_height = 42;

  configureRowDragging();
  configureZoom();

  gantt.attachEvent("onAfterTaskUpdate", gantt.render);
  gantt.attachEvent("onBeforeGanttRender", adjustScale);
};
