import BasicDatatableController from "./basic_datatable_controller";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import EditActionCellRenderer from "@/components/cell_renderers/edit_action_cell_renderer";
import {  Agreement, JSONApiResponseObject } from "types";
import CommentsCellRenderer from "@/components/cell_renderers/comments_cell_renderer";
import CurrencyCellRenderer from "@/components/cell_renderers/currency_cell_renderer";
import BooleanCellRenderer from "@/components/cell_renderers/boolean_cell_renderer";
import { formatDate } from "@/utils/dates";
import UploadsCellRenderer from "@/components/cell_renderers/uploads_cell_renderer";

const cellRendererParamsFn = (params: ICellRendererParams) => {
  const line = params.data as Agreement;

  return {
    resourceType: "agreements",
    resourceId: line.id,
    uploadType: "agreement"
  };
};

export default class extends BasicDatatableController {
  columnDefs(): ColDef[] {
    return [
      { field: "attributes.name", headerName: "Agreement", flex: 1, minWidth: 200 },
      { field: "attributes.applicable", headerName: "Applicable" },
      { field: "attributes.dated", headerName: "Dated", cellRenderer: (params: ICellRendererParams) => formatDate(params.value) },
      { field: "attributes.executed", headerName: "Executed", cellRenderer: BooleanCellRenderer },
      { field: "attributes.total", headerName: "Total", cellRenderer: CurrencyCellRenderer },
      { field: "attributes.paymentFrequency", headerName: "Payment Frequency" },
      ...(
        this.readonly
          ? []
          : [
            {
              pinned: "right",
              width: 50,
              cellRenderer: EditActionCellRenderer,
              cellRendererParams: cellRendererParamsFn,
              resizable: false
            },
            {
              field: "attributes.uploadsCount",
              headerName: "",
              pinned: "right",
              width: 50,
              cellRenderer: UploadsCellRenderer,
              cellRendererParams: cellRendererParamsFn,
              resizable: false
            }
          ] as ColDef[]
      ), 
      {
        cellRenderer: CommentsCellRenderer,
        field: "attributes.commentsCount",
        headerName: "",
        pinned: "right",
        resizable: false,
        width: 50,
        cellRendererParams: cellRendererParamsFn,
      },
    ];
  }

  public agreementUpdated(e: CustomEvent): void {
    const response = e.detail as JSONApiResponseObject<Agreement>;
    const updatedRow = response.data;

    this.updateRows(updatedRow);
  }
}
