import { Controller } from "@hotwired/stimulus";

/**
 *  Manages the tabs that are used for switching content
 *  on a page. The tab bar will load content through hotwire
 *  frames, but this controller will manage the selection
 *  state of each tab
 **/
export default class extends Controller {
  static targets = ["tabs", "search"];

  declare clickedTab: HTMLElement | null;

  declare readonly tabsTarget: HTMLElement;
  declare readonly searchTarget: HTMLElement;

  declare readonly hasSearchTarget: boolean;


  declare tabClickedCallback: (e: Event) => void;
  declare tabLoadedCallback: (e: Event) => void;

  connect() {
    this.tabLoadedCallback = this.tabLoaded.bind(this);
    this.tabClickedCallback = this.tabClicked.bind(this);

    this.tabsTarget.addEventListener("turbo:click", this.tabClickedCallback);
    document.addEventListener(
      "turbo:before-frame-render",
      this.tabLoadedCallback
    );
  }

  disconnect() {
    document.removeEventListener("turbo:click", this.tabClickedCallback);
    this.tabsTarget.removeEventListener("turbo:click", this.tabClickedCallback);
  }

  private tabClicked(e: Event): void {
    this.clickedTab = <HTMLElement>e.target;
  }

  private tabLoaded(e: Event): void {
    // If a tab was clicked then we'll swap, but it might have
    // been triggered by a link within the current tab. If that's
    // the case we don't want to swap things around
    if (!this.clickedTab) return;

    // Mark old tab as no longer active
    const activeTab = this.tabsTarget.querySelectorAll(".tab-item.active");
    activeTab[0].classList.remove("active"); 

    // toggle the search bar
    if (this.hasSearchTarget) {
      const showSearch = this.clickedTab?.dataset?.search === "true";
      const classList = this.searchTarget.classList;
      showSearch ? classList.remove("hidden") : classList.add("hidden");
    }

    // Mark new tab as active
    this.clickedTab?.classList?.add("active");
    this.clickedTab = null;
  }
}
