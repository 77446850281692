import dateTemplate, { DateColumn } from "./column_templates/date_column_template";
import constructionStageTemplate from "./column_templates/construction_stage_column_template";
import percentageTemplate from "./column_templates/percentage_column_template";
import ragStatusTemplate from "./column_templates/rag_status_column_template";
import statusTemplate from "./column_templates/status_column_template";

export default [
  {
    name: "text",
    label: "Name",
    tree: true,
    width: "*",
    min_width: 200,
    resize: true
  },
  { name: "construction_stage", label: "Construction stage", template: constructionStageTemplate, resize: true },
  { name: "status", label: "Status", template: statusTemplate, resize: true },
  { name: "progress", label: "PC", template: percentageTemplate, resize: true },
  { name: "rag_status", label: "RAG", template: ragStatusTemplate, resize: true },
  { name: "start_date", label: "Start", template: dateTemplate(DateColumn.StartDate), resize: true },
  { name: "end_date", label: "End", template: dateTemplate(DateColumn.EndDate), resize: true },
  { name: "duration", label: "Duration", resize: true }
];
