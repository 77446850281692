import BasicDatatableController from "./basic_datatable_controller";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import EditActionCellRenderer from "@/components/cell_renderers/edit_action_cell_renderer";
import CommentsCellRenderer from "@/components/cell_renderers/comments_cell_renderer";
import {  JSONApiResponseObject, ReportAction } from "types";
import { dateComparator, formatDate } from "@/utils/dates";

const cellRendererParamsFn = (params: ICellRendererParams) => {
  const line = params.data as ReportAction;

  return {
    resourceType: "report_actions",
    resourceId: line.id,
  };
};

export default class extends BasicDatatableController {
  columnDefs(): ColDef[] {
    return [
      { field: "attributes.name", headerName: "Name", flex: 1, sortable: true },
      { field: "attributes.description", headerName: "Description", flex: 1 },
      { field: "attributes.status", headerName: "Status", sortable: true },
      { field: "attributes.deadline", headerName: "Deadline", cellRenderer: (params: ICellRendererParams) => formatDate(params.value), sortable: true },
      { field: "attributes.ownerName", headerName: "Owner", sortable: true },
      { field: "attributes.propertyName", headerName: "Property", sortable: true, comparator: dateComparator  },
      {
        field: "attributes.commentsCount",
        headerName: "",
        pinned: "right",
        width: 75,
        cellRenderer: CommentsCellRenderer,
        cellRendererParams: cellRendererParamsFn,
        resizable: false
      },
      {
        pinned: "right",
        width: 50,
        cellRenderer: EditActionCellRenderer,
        cellRendererParams: cellRendererParamsFn,
        resizable: false
      }
    ];
  }

  public actionUpdated(e: CustomEvent): void {
    const response = e.detail as JSONApiResponseObject<ReportAction>;
    const updatedRow = response.data;

    this.updateRows(updatedRow);
  }
}
