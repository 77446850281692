import { InsurancePolicy, JSONApiResponseObject } from "types";
import BasicDatatableController from "./basic_datatable_controller";
import { GridOptions } from "ag-grid-community";

export default class extends BasicDatatableController {
  public policyAdded(e: CustomEvent) {
    const response = e.detail as JSONApiResponseObject<InsurancePolicy>;
    const newRow = response.data;
    this.addRows(newRow);
  }

  public policyRemoved(e: CustomEvent) {
    const response = e.detail;
    this.removeRows(response);
  }

  public policyUpdated(e: CustomEvent) {
    const response = e.detail as JSONApiResponseObject<InsurancePolicy>;
    const updatedRow = response.data;

    this.updateRows(updatedRow);
  }

  protected gridOptions(): GridOptions<any> {
    return {
      defaultColDef: { resizable: true },
      domLayout: "autoHeight",
      rowHeight: 42,
    };
  }
}
