import { Controller } from "@hotwired/stimulus";
import { useTransition } from "stimulus-use";

export default class extends Controller {
  static targets = ["menu"];

  declare readonly menuTarget: HTMLElement;
  declare readonly toggleTransition: (event?: Event) => void;
  declare readonly leave: (event?: Event) => void;
  declare transitioned: false;


  connect() {
    useTransition(this, {
      element: this.menuTarget
    });
  }

  hide(event: Event): void {
    // @ts-ignore
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains("hidden")) {
      this.leave();
    }
  }

  toggle() {
    this.toggleTransition();
  }
}
