const STATUS_CLASSES: { [index: string]: string } = {
  unstarted: "italic text-gray-400 bg-transparent",
  in_progress: "bg-green-50 text-green-700 ring-1 ring-green-600/20",
  delayed: "bg-red-200 text-red-800 ring-1 ring-red-600/10",
  paused: "bg-orange-200 text-orange-800 ring-1 ring-orange-800/20",
  completed: "bg-gray-200 text-gray-900 ring-1 ring-gray-500/10",
};

const STATUS_LABELS: { [index: string]: string } = {
  unstarted: "Not started",
  in_progress: "In progress",
  delayed: "Delayed",
  paused: "Paused",
  completed: "Completed",
};

/**
 * Generates the html for displaying a tasks
 * status correctly in the gantt data grid
 */
export default (task: any): string => {
  if (!task.status) return "";
  if (!STATUS_LABELS[task.status]) return "";

  const classes = STATUS_CLASSES[task.status];
  const label = STATUS_LABELS[task.status];
  return `<span class="rounded-full px-2 text-sm ring-inset ${classes}">${label}</span>`;
};
