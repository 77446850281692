import { ICellRendererComp, ICellRendererParams } from "ag-grid-community";

export default class HtmlCellRenderer implements ICellRendererComp {
  declare gui: HTMLElement;
  declare content: HTMLSpanElement;
  declare params: any;

  init(params: any) {
    this.params = params;
    this.gui = document.createElement("div");
    this.content = document.createElement("span");
    this.content.innerHTML = this.params.value as string;

    this.gui.append(this.content);
  }

  public refresh(params: ICellRendererParams) {
    this.params.value = params.value;
    this.content.innerHTML = params.value as string;

    return true;
  }

  public getGui() {
    return this.gui;
  }
}
