import { ICellRendererComp, ICellRendererParams } from "ag-grid-community";
import editIcon from "@images/icons/edit.svg";

export default class EditActionCellRenderer implements ICellRendererComp {
  declare gui: HTMLElement;
  declare params: any;
  declare icon: HTMLElement;

  init(params: any) {
    this.params = params;
    this.gui = this.createGui();
  }

  private createGui(): HTMLElement {
    const gui = document.createElement("a");
    gui.classList.add(
      "block",
      "cursor-pointer",
      "flex",
      "h-full",
      "items-center",
      "justify-center",
      "w-full"
    );
    gui.href = `/${this.params.resourceType}/${this.params.resourceId}/edit`;
    gui.setAttribute("data-turbo-stream", "");

    // The button to add a comment
    this.icon = document.createElement("span");
    this.icon.classList.add("relative", "text-gray-500");
    this.icon.innerHTML = editIcon;

    gui.append(this.icon);
    return gui;
  }

  getGui() {
    return this.gui;
  }

  refresh(_params: ICellRendererParams<any, any>): boolean {
    return true;
  }
}
