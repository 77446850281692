import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["datePicker"];

  declare readonly datePickerTarget: HTMLInputElement;

  connect() {
    flatpickr(this.datePickerTarget, {
      altInput: true,
      altFormat: "d F Y",
      dateFormat: "Y/m/d",
    });
  }
}
