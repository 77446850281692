import { Controller } from "@hotwired/stimulus";
import { autofill, MapboxAddressMinimap, config, MapboxHTMLEvent } from "@mapbox/search-js-web";
import mapboxgl, {  Map, MapboxEvent, MapMouseEvent, Marker } from "mapbox-gl";
import { MAPBOX_ACCESS_TOKEN } from "@/env";
import {
  AutofillCollectionOptions,
  AutofillCollectionType,
} from "@mapbox/search-js-web/dist/autofill";
import { AutofillOptions } from "@mapbox/search-js-core";

const AUTOFILL_OPTIONS: Partial<AutofillOptions> = {
  country: "gb",
};

export default class extends Controller {
  static targets = [
    "minimap",
    "latitudeInput",
    "longitudeInput",
  ];

  declare readonly minimapTarget: HTMLElement;
  declare readonly latitudeInputTarget: HTMLInputElement;
  declare readonly longitudeInputTarget: HTMLInputElement;

  declare autofillOptions: AutofillCollectionOptions;
  declare autofillCollection: AutofillCollectionType;
  declare minimap: Map;
  declare mapMarker: Marker;

  connect() {
    config.accessToken = MAPBOX_ACCESS_TOKEN;
    mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

    this.autofillCollection = autofill({
      options: AUTOFILL_OPTIONS,
    });

    this.initMiniMap();

    this.autofillCollection.addEventListener("retrieve", async (e) => {
      const feature = e.detail.features[0];

      //this.minimap.feature = feature;
      const [lng, lat] = feature.geometry.coordinates;
      this.updateMarker([lng, lat]);
      this.setCoordinates([lng, lat]);
    });
  }

  private initMiniMap(): void {
    this.minimap = new Map({
      container: this.minimapTarget,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [-0.118092, 51.509865],
      zoom: 12
    })
  }


  private setCoordinates(lnglat: [number, number]) {
    const [lng, lat] = lnglat;

    this.latitudeInputTarget.value = lat.toString();
    this.longitudeInputTarget.value = lng.toString();
  }

  private updateMarker(lnglat: [number, number]) {
    const onDragEnd = () => {
      const lngLat = this.mapMarker.getLngLat();
      this.setCoordinates([lngLat.lng, lngLat.lat]);
    };

    if (!this.mapMarker) {
      this.mapMarker = new Marker({ draggable: true })
      // @ts-ignore
      window.marker = this.mapMarker;
      this.mapMarker
        .setLngLat(lnglat)
        .addTo(this.minimap)
        .on("dragend", onDragEnd);
    } else {
      this.mapMarker.setLngLat(lnglat);
    }
 
    this.minimap.flyTo({
      center: lnglat,
      zoom: 16
    })
  }
}
