import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
  static targets = ["menu"];

  /**
   * Makes a turbo stream request to load the
   * various contents of the page
   *
   * @param e
   * @returns
   */
  async viewLoan(e: Event): Promise<void> {
    const loanElement = <HTMLElement>e.target;
    const loanRow = loanElement.closest("tr");

    if (!loanRow) return;

    const id = loanRow.dataset.id;
    const resp = await get(`/loans/${id}`, {
      responseKind: "turbo-stream",
    });

    const { url } = resp.response;
    history.pushState(history.state, "", url);
    Turbo.navigator.history.push(url);
  }
}
