import { ICellRendererComp, ICellRendererParams } from "ag-grid-community";
import { User } from "types";

export default class UserNameAndAvatarCellRenderer implements ICellRendererComp {
  declare gui: HTMLElement;
  declare params: any;

  init(params: any) {
    this.params = params;
    this.gui = this.createGui();
  }

  private createGui(): HTMLElement {
    const gui = document.createElement("div");
    const avatar = this.buildAvatarElement();
    const name = this.buildNameElement();
    
    gui.classList.add("h-full", "flex", "flex-row", "items-center");

    gui.append(avatar);
    gui.append(name);

    return gui;
  }

  getGui() {
    return this.gui;
  }

  refresh(params: ICellRendererParams) {
    this.params.value = params.value;

    return true;
  }

  private buildAvatarElement(): HTMLElement {
    const container = document.createElement("div")
    const user = this.params.value as User;

    container.classList.add("mr-4");
    container.innerHTML = user.avatarHtml;

    return container;
  }

  private buildNameElement(): HTMLElement {
    const user = this.params.value as User;

    const container = document.createElement("div");
    container.classList.add("flex", "flex-col");

    const nameEl = document.createElement("span")
    nameEl.classList.add("leading-none", "text-gray-900", "mb-1");
    nameEl.textContent = user.fullName;

    const emailEl = document.createElement("p")
    emailEl.classList.add("leading-none");
    emailEl.textContent = user.email;

    container.append(nameEl);
    container.append(emailEl);

    return container;
  }
}
