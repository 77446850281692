import { debounce } from "@/utils/utils";
import { Controller } from "@hotwired/stimulus";
import { Grid, GridOptions } from "ag-grid-community";
import { ColDef } from "ag-grid-community";

export default class extends Controller {
  static targets = ["table"];

  declare readonly: boolean;
  declare url: string;
  declare agGridOptions: GridOptions;
  declare grid: Grid;
  declare readonly tableTarget: HTMLElement;

  declare searchBox: HTMLInputElement | null;
  declare onSearchTermUpdated: (e: Event) => void;

  connect() {
    this.readAttributes();
    this.generateGridOptions();
    this.initTable();
    this.registerSearchListeners();

    const headers = new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    });
    fetch(this.url, { headers })
      .then((response) => response.json())
      .then((data) => this.setData(data));
  }

  disconnect() {
    this.agGridOptions.api?.destroy();
    this.removeSearchListeners();
  }

  /**
   * Removes the given row from the table
   *
   * @param row
   */
  protected addRows(rows: any | any[]) {
    const transaction = {
      add: Array.isArray(rows) ? rows : [rows],
    };

    this.agGridOptions.api?.applyTransaction(transaction);
  }

  /**
   * Notify the table that a row had a comment added
   * to it. This will increment the `commentsCount`
   * attribute for that row, and refresh the comment
   * cell.
   * @param e CustomEvent triggered by stimulus
   */
  public commentCreated(e: CustomEvent) {
    const row = this.agGridOptions.api?.getRowNode(e.detail.resourceId);
    row?.setDataValue(
      "attributes.commentsCount",
      row.data.attributes.commentsCount + 1
    );
  }

  /**
   * Returns a list of columns to be rendered in the table.
   * See ag-grid documentation for options.
   *
   * @returns
   */
  protected columnDefs(): ColDef[] {
    return [];
  }

  /**
   * Notify the table that a row had a document uploaded 
   * to it. This will increment the `uploadsCount`
   * attribute for that row, and refresh the uploads 
   * cell.
   * @param e CustomEvent triggered by stimulus
   */
  public documentUploaded(e: CustomEvent) {
    const row = this.agGridOptions.api?.getRowNode(e.detail.resourceId);
    row?.setDataValue(
      "attributes.uploadsCount",
      row.data.attributes.uploadsCount + 1
    );
  }

  /**
   * Notify the table that a row had a document deleted
   * from it. This will decrement the `uploadsCount`
   * attribute for that row, and refresh the uploads
   * cell.
   * @param e CustomEvent triggered by stimulus
   */
  public documentDeleted(e: CustomEvent) {
    const row = this.agGridOptions.api?.getRowNode(e.detail.resourceId);
    row?.setDataValue(
      "attributes.uploadsCount",
      row.data.attributes.uploadsCount - 1
    );
  }

  /**
   * Returns any grid options that should override the defaults.
   **/
  protected gridOptions(): GridOptions {
    return {};
  }

  /**
   * Generates an object containing the relevant ag-grid
   * configuration required for a datatable. Resulting
   * configuration is stored in the `agGridOptions` attribute
   */
  private generateGridOptions() {
    this.agGridOptions = {
      columnDefs: this.columnDefs(),
      rowHeight: 56, // 4rem @ 14px
      getRowId(params) {
        return params.data.id;
      },
      ...this.gridOptions(),
    };
  }

  /**
   * Initialises the datatable itself.
   */
  private initTable(): void {
    this.grid = new Grid(this.tableTarget, this.agGridOptions);
  }

  private onSearchTermUpdatedCallback(e: Event): void {
    if (!this.searchBox) return;

    const searchTerm = this.searchBox.value;
    this.agGridOptions?.api?.setQuickFilter(searchTerm);
  }

  /**
   * Read data attributes from the table element
   * when the controller first loads
   */
  protected readAttributes() {
    const url = this.tableTarget.dataset.datasource;
    const readonly = this.tableTarget.dataset.readonly === "true";

    this.url = url || "";
    this.readonly = readonly;
  }

  /**
   * Registers listeners for the search field on the page
   * if one is present. If found, typing in that field will
   * filter the results in the table. This uses the internal
   * ag-grid filtering and does not perform a search on the
   * server. 
   **/ 
  private registerSearchListeners() {
    this.searchBox = document.getElementById("page-search") as HTMLInputElement;
    if (!this.searchBox) return;

    this.onSearchTermUpdated = debounce((e: Event) => this.onSearchTermUpdatedCallback(e)).bind(this);
    this.searchBox.addEventListener("keyup", this.onSearchTermUpdated);
  } 

  /**
   * Removes the given row from the table
   *
   * @param row
   */
  protected removeRows(rows: any | any[]) {
    const transaction = {
      remove: Array.isArray(rows) ? rows : [rows],
    };

    this.agGridOptions.api?.applyTransaction(transaction);
  }

  /**
   * If a search field is present, the event listeners
   * previously bound to it will be removed. 
   **/ 
  private removeSearchListeners() {
    if (!this.searchBox) return;
    
    this.searchBox.removeEventListener("keyup", this.onSearchTermUpdated);
    this.searchBox.value = "";
  }

  /**
   * Loads the data into the table once the server request
   * has completed
   *
   * @param data Data returned by the server
   */
  protected setData(data: any) {
    this.agGridOptions.api?.setRowData(data.data);
  }

  /**
   * Updates the data for the specified rows
   **/
  protected updateRows(rows: any | any[]) {
    const transaction = {
      update: Array.isArray(rows) ? rows : [rows],
    };

    this.agGridOptions.api?.applyTransaction(transaction);
  }
}
