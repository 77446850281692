import { Controller } from "@hotwired/stimulus";
import { gantt } from "dhtmlx-gantt";
import { enter, leave } from "el-transition";
import { Instance as FlatPickr } from "flatpickr/dist/types/instance";
import Quill from "quill";

export default class extends Controller {
  static targets = [
    "container",
    "modal",
    "saveButton",
    "title",

    "name",
    "constructionStage",
    "description",
    "status",
    "ragStatus",
    "ragAdditionalInfo",
    "ragAdditionalInfoContainer",
    "startDate",
    "endDate",
  ];

  declare task: any;

  declare readonly containerTarget: HTMLElement;
  declare readonly modalTarget: HTMLElement;
  declare readonly saveButtonTarget: HTMLElement;
  declare readonly titleTarget: HTMLHeadingElement;

  // Possible input targets. Not all will be populated
  // depending on the type of modal being displayed
  declare readonly nameTarget: HTMLInputElement;
  declare readonly constructionStageTarget: HTMLInputElement;
  declare readonly descriptionTarget: HTMLElement;
  declare readonly statusTarget: HTMLSelectElement;
  declare readonly ragStatusTarget: HTMLSelectElement;
  declare readonly ragAdditionalInfoContainerTarget: HTMLElement;
  declare readonly ragAdditionalInfoTarget: HTMLElement;
  declare readonly startDateTarget: HTMLElement;
  declare readonly endDateTarget: HTMLElement;

  // Determine if we have some of the above targets
  declare readonly hasDescriptionTarget: boolean;
  declare readonly hasConstructionStageTarget: boolean;
  declare readonly hasStatusTarget: boolean;
  declare readonly hasRagStatusTarget: boolean;
  declare readonly hasStartDateTarget: boolean;
  declare readonly hasEndDateTarget: boolean;
  declare readonly hasRagAdditionalInfoTarget: boolean;

  /**
   * Transition the modal window out hiding it from
   * the UI, and clears the form
   */
  async close(evt: CustomEvent) {
    evt.preventDefault();

    leave(this.modalTarget).then(() => this.modalTarget.classList.remove("flex"));;
    await leave(this.containerTarget);

    this.clearForm();
  }

  /**
   * Opens the modal, and populates the form with
   * values from the task that triggered the event.
   * This is triggered by an event rather than called
   * directly.
   *
   * @param evt
   */
  open(evt: CustomEvent): void {
    evt.preventDefault();

    this.task = evt.detail.task;
    this.populateForm(this.task);
    if (this.taskType(this.task) === "task") {
      this.toggleRagAdditionalInfo();
    }

    enter(this.containerTarget);
    enter(this.modalTarget).then(() => this.modalTarget.classList.add("flex"));
  }

  /**
   * Updates or creates the task/building record.
   * The actual persistence is handled by the gantt
   * chart. This function will simply set the values
   * on the task object, and then pass it on to the
   * gantt library
   */
  save(evt: CustomEvent): void {
    evt.preventDefault();

    const formData = {
      text: this.nameTarget.value,
      ...this.getDescription(),
      ...this.getConstructionStage(),
      ...this.getRagStatus(),
      ...this.getRagAdditionalInfo(),
      ...this.getStatus(),
      ...this.getStartDate(),
      ...this.getEndDate()
    };

    if (this.task.$new) {
      gantt.addTask(formData);
    } else {
      const ganttTask = gantt.getTask(this.task.id);
      Object.assign(ganttTask, formData);

      gantt.updateTask(this.task.id, undefined);
    }

    this.close(evt);
  }

  /**
   * Clear all values in the form, resetting it back
   * to its default state
   */
  private clearForm(): void {
    this.setDescription("");
    this.setConstructionStage("na");
    this.setStatus("");
    this.setStartDate("");
    this.setEndDate("");
    this.setRagStatus("");
    this.setRagAdditionalInfo("");
  }
 
  private getConstructionStage(): { [index: string]: string } {
    if (!this.hasConstructionStageTarget) return {};

    return { construction_stage: this.constructionStageTarget.value };
  }

  /**
   * Get the description, returned as an object to be
   * merged into the task object
   *
   * @returns Object with `description` key
   */
  private getDescription(): { [index: string]: string } {
    if (!this.hasDescriptionTarget) return {};

    const quill = Quill.find(this.descriptionTarget);
    return { description: quill.root.innerHTML };
  }

  private getEndDate(): { [index: string]: Date } {
    if (!this.hasEndDateTarget) return {};

    // @ts-ignore
    const flatpickr: FlatPickr = this.endDateTarget._flatpickr;
    const selectedDate = flatpickr.selectedDates[0];

    return { end_date: selectedDate };
  }

  /**
   *  Get the additional info for the RAG status. This is
   *  presented to the user if they choose any non-green
   *  status.
   *
   *  @returns Object with `rag_additional_info` key 
   **/ 
  private getRagAdditionalInfo(): { [index: string]: string } {
    if (!this.hasRagAdditionalInfoTarget) return {};

    const quill = Quill.find(this.ragAdditionalInfoTarget);
    return { rag_additional_info: quill.root.innerHTML };
  }

  /**
   * Get the RAG status, returned as an object to be
   * merged into the task object
   *
   * @returns Object with `rag_status` key
   */
  private getRagStatus(): { [index: string]: string } {
    if (!this.hasRagStatusTarget) return {};

    return { rag_status: this.ragStatusTarget.value };
  }

  /**
   * Get the status, returned as an object to be
   * merged into the task object
   *
   * @returns Object with `status` key
   */
  private getStatus(): { [index: string]: string } {
    if (!this.hasStatusTarget) return {};

    return { status: this.statusTarget.value };
  }

  private getStartDate(): { [index: string]: Date } {
    if (!this.hasStartDateTarget) return {};

    // @ts-ignore
    const flatpickr: FlatPickr = this.startDateTarget._flatpickr;
    const selectedDate = flatpickr.selectedDates[0];

    return { start_date: selectedDate };
  } 

  /**
   * Fills in the input fields of the form with
   * the values of the provided task
   *
   * @param task
   */
  private populateForm(task: any): void {
    this.setTitle(task);
    this.setSaveButtonText(task);

    this.nameTarget.value = task.text;
    this.setDescription(task.description || "");
    this.setConstructionStage(task.construction_stage || "na")
    this.setEndDate(task.end_date);
    this.setStatus(task.status);
    this.setStartDate(task.start_date);
    this.setRagStatus(task.rag_status);
    this.setRagAdditionalInfo(task.rag_additional_info);
  }

  /**
   * Populate the construction stage dropdown if it is
   * present
   *
   * @param status
   */
  private setConstructionStage(stage: string) {
    if (!this.hasConstructionStageTarget) return;

    this.constructionStageTarget.value = stage;
  }

  /**
   * Populates the description if it is present
   *
   * @param description
   * @returns
   */
  private setDescription(description: string) {
    if (!this.hasDescriptionTarget) return;

    const quill = Quill.find(this.descriptionTarget);
    quill.root.innerHTML = "";
    quill.clipboard.dangerouslyPasteHTML(0, description || "");
  }

  /**
   * Populate the end date picker if it is present
   * @param endDate 
   * @returns
   */
  private setEndDate(endDate: string | Date) {
    if (!this.hasEndDateTarget) return;

    // @ts-ignore
    const flatpickr: FlatPickr = this.endDateTarget._flatpickr;
    flatpickr.setDate(endDate);
  }

  /**
   * Populate the RAG additional info text area
   *
   * @param text
   */
  private setRagAdditionalInfo(text: string) {
    if (!this.hasRagAdditionalInfoTarget) return;

    const quill = Quill.find(this.ragAdditionalInfoTarget);
    quill.root.innerHTML = "";
    quill.clipboard.dangerouslyPasteHTML(0, text || "");
  }

  /**
   * Populate the RAG status dropdown if it is
   * present
   *
   * @param status
   */
  private setRagStatus(status: string) {
    if (!this.hasRagStatusTarget) return;

    this.ragStatusTarget.value = status;
  }

  /**
   * Sets the text on the "save" button. This changes
   * depending on the type of task, and whether it is
   * a new one or an existing one
   *
   * @param task
   */
  private setSaveButtonText(task: any) {
    const type = this.taskType(task);
    const verb = task.$new ? "Add" : "Update";

    this.titleTarget.textContent = `${verb} ${type}`;
  }

  /**
   * Populate the start date picker if it is present
   * @param startDate
   * @returns
   */
  private setStartDate(startDate: string | Date) {
    if (!this.hasStartDateTarget) return;

    // @ts-ignore
    const flatpickr: FlatPickr = this.startDateTarget._flatpickr;
    flatpickr.setDate(startDate);
  }

  /**
   * Populate the status dropdown on the form if
   * if is present.
   *
   * @param status
   * @returns
   */
  private setStatus(status: string) {
    if (!this.hasStatusTarget) return;

    this.statusTarget.value = status;
  }

  /**
   * Set the title of the modal, depending on the
   * type of task it is and whether it's being
   * created or updated
   * @param task
   */
  private setTitle(task: any): void {
    const type = this.taskType(task);
    const prefix = task.$new ? "Add a" : "Update";

    this.titleTarget.textContent = `${prefix} ${type}`;
  }

  private taskType(task: any): string {
    const type = task.type;
    return type === "project" ? "section header" : "task";
  }

  private toggleRagAdditionalInfo() : void {
    const ragStatus = this.getRagStatus().rag_status;

    if (ragStatus === "green") {
      this.ragAdditionalInfoContainerTarget.classList.add("hidden");
    } else {
      this.ragAdditionalInfoContainerTarget.classList.remove("hidden");
    }
  }
}
