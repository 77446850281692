import { csrfHeaders, injectCsrfToken } from "@/utils/ajax";
import { Controller } from "@hotwired/stimulus";
import Dropzone, { DropzoneFile } from "dropzone";
import {
  JSONApiArrayResponse,
  JSONApiResponseObject,
  JSONRedirectResponse,
  Upload,
} from "types";
import { get } from "@rails/request.js";

interface ServerBackedDropzoneFile extends DropzoneFile {
  id: string;
}

interface ReadConfigOpts {
  as?: string | null;
  parse?: boolean | null;
}

export default class extends Controller {
  static targets: string[] = [
    "dropzone",
    "previewsContainer",
    "previewTemplate",
  ];

  declare readonly dropzoneTarget: HTMLElement;
  declare readonly previewsContainerTarget: HTMLElement;
  declare readonly previewTemplateTarget: HTMLElement;
  declare dropzone: Dropzone;
  declare redirectUrl: string;

  /**
   * Internal component configuration to tailor
   * the behaviour of
   */
  config: { [index: string]: any } = {
    allowMultipleFiles: true,
    autoUpload: true,
    loadExistingFiles: false,
    redirectOnCompletion: false,
    uploadType: null,
    uploadSubtype: null,
    assocName: null,
    url: null,
  };

  connect() {
    const ctrl = this;

    this.configureController();

    const config = JSON.parse(
      this.dropzoneTarget.dataset["dzConfig"] || "{}"
    ) as Dropzone.DropzoneOptions;

    this.dropzone = new Dropzone(this.dropzoneTarget, {
      init() {
        ctrl.loadExistingFiles(this);

        if (!ctrl.config.allowMultipleFiles) {
          this.hiddenFileInput?.removeAttribute("multiple");
        }
      },
      autoProcessQueue: this.config.autoUpload,
      previewsContainer: this.previewsContainerTarget,
      previewTemplate: this.previewTemplateTarget.innerHTML,
      url: this.appendUrlParams(this.config.url),
      ...config,
    });

    this.dropzone
      .on("addedfile", (file: ServerBackedDropzoneFile) => {
        // If this is single selection, then remove the first
        // file in place of this one
        if (!this.config.allowMultipleFiles && this.dropzone.files.length > 1) {
          this.dropzone.removeFile(this.dropzone.files[0]);
        }

        const preview = file.previewTemplate;

        // If the file already has an ID then this is an existing
        // file being added, so we'll add its ID into the preview
        // to allow deletion later
        if (file.id) {
          preview.setAttribute("data-upload-id", file.id);
        }
      })
      .on("removedfile", async (file: ServerBackedDropzoneFile) => {
        // Removed from DZ, so we'll delete it on the server
        const preview = file.previewTemplate;
        const id = preview.dataset["uploadId"];
        const url = `/uploads/${id}`;

        if (!id) return;

        const response = await fetch(url, {
          method: "DELETE",
          headers: csrfHeaders(),
        });

        const jsonResponse =
          (await response.json()) as JSONApiResponseObject<Upload>;
        const upload = jsonResponse.data.attributes;

        this.dispatch("deleted", {
          target: undefined,
          detail: {
            resourceId: upload.resourceId,
            resourceType: upload.resourceType,
          },
        });
      })
      .on("queuecomplete", () => {
        if (this.config.redirectOnCompletion && this.redirectUrl) {
          window.Turbo.visit(this.redirectUrl);
        }
      })
      .on("sending", (_file: ServerBackedDropzoneFile, xhr: XMLHttpRequest) =>
        injectCsrfToken(xhr)
      )
      .on(
        "success",
        (
          file: ServerBackedDropzoneFile,
          data: string | JSONApiResponseObject<Upload> | JSONRedirectResponse
        ) => {
          if (typeof data === "string") return;

          if ("action" in data && data.action === "redirect") {
            this.redirectUrl = data.url;
          } else if ("data" in data) {
            const uploadResponse = data.data;
            const preview = file.previewTemplate;
            const upload = uploadResponse.attributes;

            preview.setAttribute("data-upload-id", upload.id.toString());
            preview.querySelector(".download")?.classList.remove("hidden");

            this.dispatch("uploaded", {
              target: undefined,
              detail: {
                resourceId: upload.resourceId,
                resourceType: upload.resourceType,
              },
            });
          }
        }
      )
      .on("error", (file: ServerBackedDropzoneFile) => {
        const preview = file.previewTemplate;
        const downloadIcon = preview.querySelector(".download");
        downloadIcon?.classList.add("hidden");
      });
  }

  /**
   * Reads the configuration in the data attributes and
   * loads them into the `config` variable
   */
  private configureController(): void {
    Object.assign(this.config, {
      ...this.readConfig("autoUpload", { parse: true }),
      ...this.readConfig("loadExistingFiles", { parse: true }),
      ...this.readConfig("multiple", { as: "allowMultipleFiles", parse: true }),
      ...this.readConfig("redirectOnCompletion", { parse: true }),
      ...this.readConfig("uploadSubtype"),
      ...this.readConfig("uploadType"),
      ...this.readConfig("assocName"),
      ...this.readConfig("url"),
    });
  }

  public download(e: Event): void {
    const clickedEl = e.target as HTMLElement;
    const upload = clickedEl.closest(".file-upload__preview") as HTMLElement;
    const uploadId = upload.dataset["uploadId"];

    window.location.href = `/uploads/${uploadId}/download`;
  }

  /**
   * Loads any existing files that have already been uploaded.
   * Only applicable if the config item `loadExistingFIles` is
   * true.
   *
   * @param dropzone
   */
  private async loadExistingFiles(dropzone: Dropzone): Promise<void> {
    if (!this.config.loadExistingFiles) return;

    const uploadsURL = this.dropzoneTarget.dataset.existingFilesUrl;
    if (!uploadsURL) return;

    const response = await fetch(this.appendUrlParams(uploadsURL));
    const jsonResponse =
      (await response.json()) as JSONApiArrayResponse<Upload>;
    const uploads = jsonResponse.data;

    uploads.forEach((uploadResponse) => {
      const upload = uploadResponse.attributes;
      const mockFile = {
        id: upload.id,
        name: upload.filename,
        size: upload.filesize,
        accepted: true
      };
      // @ts-ignore
      dropzone.files.push(mockFile);
      dropzone.displayExistingFile(
        mockFile,
        upload.preview,
        undefined,
        undefined,
        false
      );
    });
  }

  /**
   * Manually trigger the uploading of the file(s). This
   * is only applicable if the controller is configured
   * not to automatically upload
   */
  processQueue() {
    this.dropzone.processQueue();
  }

  /**
   * Reads the configure supplied as data attributes. Returns
   * an object containing the configuration retrieved, or an
   * empty object if no value was found.
   *
   * The key of the resulting object will either be the same
   * name as the data attribute being read, or alternatively,
   * the second argument can be used to specify a name by
   * supplying the `as` option. For example:
   *
   *   this.readConfig("multiple", { as: "allowMultipleFiles" })
   *
   * For configuration values that are not strings, the option
   * `parse` can be supplied to parse the value as JSON.
   *
   *   this.readConfig("multiple", { parse: true })
   *
   * @param inputName The name of the data attribute to read
   * @param outputName The optional name of the resulting configuration key
   * @returns
   */
  private readConfig(
    inputName: string,
    opts: ReadConfigOpts = {}
  ): { [index: string]: any } {
    const dataset = this.dropzoneTarget.dataset;
    const input = dataset[inputName];
    const outputName = opts.as || null;
    const parse = opts.parse || false;

    if (input === undefined) return {};

    return {
      [outputName || inputName]: parse ? JSON.parse(input) : input,
    };
  }

  private appendUrlParams(url: string): string {
    const urlParams = new URLSearchParams({
      ...(this.config.uploadType
        ? { upload_type: this.config.uploadType }
        : {}),
      ...(this.config.uploadSubtype
        ? { upload_subtype: this.config.uploadSubtype }
        : {}),
      ...(this.config.assocName
        ? { assoc: this.config.assocName }
        : {}),
    }).toString();

    if (urlParams === "") return url;

    return url.indexOf("?") === -1
      ? `${url}?${urlParams}`
      : `${url}&${urlParams}`;
  }

  /**
   * Opens the commenting sidebar for the uploaded file
   **/ 
  public async comment(e: Event) : Promise<void> {
    const clickedEl = e.target as HTMLElement;
    const upload = clickedEl.closest(".file-upload__preview") as HTMLElement;
    const uploadId = upload.dataset["uploadId"];

    let pathType = "uploads";
    await get(`/${pathType}/${uploadId}/comments`, {
      responseKind: "turbo-stream",
    });
  }
}
