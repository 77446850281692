const getCsrfToken = (): string | null => {
  const csrfMeta: HTMLMetaElement | null = document.querySelector(
    'meta[name="csrf-token"]'
  );

  return csrfMeta ? csrfMeta.content : null;
};

/**
 * Adds the `X-CSRF-Token` to an ajax request. This is
 * needed for any non GET request
 *
 * @param xhr
 */
export const injectCsrfToken = (xhr: XMLHttpRequest) => {
  const csrf = getCsrfToken();
  if (csrf) {
    xhr.setRequestHeader("X-CSRF-Token", csrf);
  }
};

/**
 * Returns an object containing the `X-CSRF-Token` header
 * which can be used in any relevant AJAX request
 * @returns
 */
export const csrfHeaders = (): { "X-CSRF-Token": string } | {} => {
  const csrf = getCsrfToken();
  if (!csrf) return {};

  return { "X-CSRF-Token": csrf };
};
