import { Controller } from "@hotwired/stimulus";
import { MAPBOX_ACCESS_TOKEN } from "@/env";
import mapboxgl from "mapbox-gl";

export default class extends Controller {
  static targets = ["map"];

  declare readonly mapTarget: HTMLElement;

  connect() {
    mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

    // If the coordinates supplied aren't valid then
    // the coordinates of London are used by default
    const lat = parseFloat(this.mapTarget.dataset.latitude || "51.509865");
    const lon = parseFloat(this.mapTarget.dataset.longitude || "-0.118092");

    const map = new mapboxgl.Map({
      container: this.mapTarget,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [lon, lat],
      zoom: 13,
    });

    new mapboxgl.Marker({
      color: "#3b82f6",
    })
      .setLngLat([lon, lat])
      .addTo(map);
  }
}
