import { gantt } from "dhtmlx-gantt";

const preventDraggingIntoTask = (): void => {
  // Only allow dragging into a building, or the
  // root, not another task
  gantt.attachEvent(
    "onBeforeTaskMove",
    (id, parentID, tindex) => {
      if (!parentID) return true;

      const parentTask = gantt.getTask(parentID);
      return parentTask.type === gantt.config.types.project;
    },
    undefined
  );
};

export default (): void => {
  preventDraggingIntoTask();
};
