import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets: string[] = ["fileUpload"];

  declare readonly fileUploadTarget: HTMLElement;

  connect() {
    const form = this.element.closest("form");
    const readonly = (<HTMLElement>this.element).dataset.readonly === "true";

    if (!readonly) {
      form?.addEventListener("submit", (e: Event) => {
        e.preventDefault();
        e.stopPropagation();

        const fileUpload = this.fileUploadTarget;

        this.dispatch("upload", {
          target: fileUpload,
          detail: {},
        });
      });
    }
  }
}
