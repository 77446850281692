import tippy, { Instance as TippyInstance, hideAll } from "tippy.js";
import { ICellRendererComp } from "ag-grid-community";
import { DropdownMenuItem } from "types";
import actionsIcon from "@images/icons/actions.svg";

export default class ActionsCellRenderer implements ICellRendererComp {
  declare isOpen: boolean;
  declare gui: HTMLElement;
  declare menu: HTMLElement | null;
  declare tippyInstance: TippyInstance;
  declare params: any;

  constructor() {
    this.isOpen = false;
    this.menu = null;
  }

  init(params: any) {
    this.params = params;
    this.gui = this.createTrigger();

    this.tippyInstance = tippy(this.gui);
    this.tippyInstance.disable();
  }

  togglePopup() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.configureTippyInstance();
      this.menu = this.createMenu();
      this.tippyInstance.setContent(this.menu);
    } else {
      this.tippyInstance.unmount();
    }
  }

  private configureTippyInstance() {
    this.tippyInstance.enable();
    this.tippyInstance.show();
    this.tippyInstance.setProps({
      trigger: "manual",
      placement: "bottom",
      animation: "fade",
      theme: "light",
      arrow: false,
      interactive: true,
      appendTo: document.body,
      hideOnClick: false,
      onShow: (instance) => {
        hideAll({ exclude: instance });
      },
      onClickOutside: (instance) => {
        this.isOpen = false;
        instance.unmount();
      },
    });
  }

  createMenu(): HTMLElement {
    let menu = this.createMenuContainer();
    let menuItems = <DropdownMenuItem[]>this.params.menuItems;

    for (let i = 0; i < menuItems.length; i++) {
      let item = this.createMenuItem(menuItems[i]);
      menu.appendChild(item);
    }

    return menu;
  }

  createMenuContainer(): HTMLElement {
    let container = document.createElement("div");
    container.classList.add(
      "z-10",
      "w-56",
      "origin-top-right",
      "divide-y",
      "divide-gray-100",
      "rounded-md",
      "focus:outline-none"
    );

    return container;
  }

  createMenuItem(menuItem: DropdownMenuItem): HTMLElement {
    let itemContainer = document.createElement("div");
    itemContainer.classList.add("py-1");

    let item = document.createElement("a");
    item.setAttribute("href", this.menuItemHref(menuItem));

    item.classList.add(
      "text-gray-700",
      "block",
      "px-4",
      "py-2",
      "cursor-pointer"
    );

    item.innerText = menuItem.label;
    item.addEventListener("click", (e: Event) => {
      this.menuItemClickHandler(e, menuItem);
    });

    itemContainer.appendChild(item);

    return itemContainer;
  }

  createTrigger(): HTMLElement {
    let trigger = document.createElement("div");
    trigger.classList.add(
      "w-full",
      "h-full",
      "flex",
      "items-center",
      "cursor-pointer"
    );
    trigger.innerHTML = actionsIcon;

    return trigger;
  }

  menuItemClickHandler(e: Event, menuItem: DropdownMenuItem) {
    this.togglePopup();
    this.params.onClick(this.params.data, menuItem, e);
  }

  getGui() {
    return this.gui;
  }

  refresh() {
    return true;
  }

  private menuItemHref(menuItem: DropdownMenuItem): string {
    const hrefOrFn = menuItem.href;

    if (!hrefOrFn) return "#";

    if (typeof hrefOrFn === "function") {
      return hrefOrFn(this.params.data);
    }

    return hrefOrFn;
  }
}
