import { StreamActions } from "@hotwired/turbo";

StreamActions.remove_table_row = function() {
	const rowId = this.getAttribute("id");
	const event = new CustomEvent("row-removed", {
		detail: {
			id: rowId
		}
	});

	this.targetElements.forEach((el: HTMLElement) => {
		el.dispatchEvent(event);
	});
}
