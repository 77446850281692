import { gantt } from "dhtmlx-gantt";

const noopString = (): string => "";

/**
 * Configures the various templates in the gantt chart
 */
export default (): void => {
  gantt.templates.grid_folder = noopString;
  gantt.templates.grid_file = noopString;

  // Sets the text that is shown to the right of each task bar
  gantt.templates.rightside_text = (
    _start: Date,
    _end: Date,
    task: any
  ): string => task.text;

  // Adds a class to each task bar so we can colour it accordingly
  gantt.templates.task_class = (_start: Date, _end: Date, task: any): string =>
    `status-${task.status}`;

  // Hide the text in the middle of the task bar
  gantt.templates.task_text = noopString;
};
