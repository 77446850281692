import { Controller } from "@hotwired/stimulus";
import currency from "currency.js";

/**
 * The `money` input type in HTML is useful, but far
 * from perfect as it doesn't automatically format
 * the number. This controller enables an input field
 * to automatically handle formatting of monetary values.
 * 
 */
export default class extends Controller {
  static targets = ["value", "display"];

  currencyConfig = { symbol: "", separator: ",", decimal: "." };

  declare readonly valueTarget: HTMLInputElement;
  declare readonly displayTarget: HTMLInputElement;

  // Callbacks for managing the state
  declare onBlur: (e: Event) => void;
  declare onKeypress: (e: KeyboardEvent) => void;

  connect(): void {
    this.onBlur = this.onBlurCallback.bind(this);  
    this.onKeypress = this.onKeypressCallback.bind(this);

    this.displayTarget.addEventListener("blur", this.onBlur);
    this.displayTarget.addEventListener("keypress", this.onKeypress);

    this.displayTarget.value = currency(
      this.valueTarget.value,
      this.currencyConfig
    ).format();
  }

  disconnect(): void {
    this.displayTarget.removeEventListener("blur", this.onBlur);
  }

  private inputChanged(): void {
    let parsed = currency(this.displayTarget.value);
    if (isNaN(parsed.value) || parsed.value < 0) {
      // Completely invalid value entered so default to zero
      parsed = currency(0)
    }

    this.displayTarget.value = parsed.format(this.currencyConfig);
    this.valueTarget.value = parsed.value.toString();
    this.valueTarget.dispatchEvent(new Event("change"));
  }

  private onBlurCallback(): void {
    // On blur, we want to parse the value and store it if valid
    this.inputChanged();
  }

  private onKeypressCallback(e: KeyboardEvent): void {
    // If the user hits `enter` to submit the form, the `blur`
    // event won't get triggered, so we'll do it manually
    if (e.key === "Enter") {
      this.inputChanged();
    }
  }
}
