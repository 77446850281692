import BasicDatatableController from "./basic_datatable_controller";
import { ColDef, GridOptions, ICellRendererParams } from "ag-grid-community";
import RAGStatusCellRenderer from "@/components/cell_renderers/rag_status_cell_renderer";

interface ReportIdMapping {
  completed: boolean;
  reportId: number;
}

export default class extends BasicDatatableController {

  // Store a mapping of report ids to columns
  declare reportIds: { [key: string]: ReportIdMapping };

  connect(): void {
    this.reportIds = {};
    super.connect();
  }

  columnDefs(): ColDef[] {
    return [
      { field: "attributes.section", headerName: "Report section", pinned: "left" }
    ];
  }

  private cellRendererParamsFn(params: ICellRendererParams) {
    const colDef = params.colDef;
    if (!colDef) return;

    const field = colDef.field;
    if (!field) return;

    const reportSection = params.data.id;
    const { reportId, completed } = this.reportIds[field];
    let url = undefined;

    if (reportId) {
      if (completed) {
        url = `/reports/${reportId}/#${reportSection}`;
      } else {
        url = `/reports/${reportId}/wizard/${reportSection}/edit`;
      }
    }

    return {
      url 
    };
  }

  /**
   * Once the data has loaded, we need to dynamically add columns to
   * this table, as apart from the first column, each column is a
   * date as supplied by the server 
   *
   * @param data Data returned by the server
   */
  protected setData(rawData: any) {
    const { columns, data } = rawData;

    const colDefs = this.agGridOptions.api?.getColumnDefs();
    if (!colDefs) return;

    colDefs.length = 0;

    columns.forEach((col: any) => {
      const colDef: ColDef = {
        headerName: col.headerName,
        field: col.field,
        headerComponentParams: (col.headerLink && col.show) ? {
          template: `<div>${col.headerName}<br /><a href="${col.headerLink}?report_table=true" data-turbo-method="delete" data-turbo-confirm="Are you sure you want to delete this report?\nThis action cannot be undone." class="text-red-500 underline">delete</a></div>`
        } : undefined,
        cellStyle: (col.report && !col.completed) ? { "background-image": "repeating-linear-gradient(315deg, #ffffff 0%, #f3f4f6 6%)"} : undefined,
        suppressMovable: true
      };

      if (col.field === "report_section") {
        colDef.pinned = "left";
      } else {
        this.reportIds[col.field] = {
          reportId: col.reportId,
          completed: col.completed
        }

        colDef.cellRenderer = RAGStatusCellRenderer;
        colDef.cellRendererParams = this.cellRendererParamsFn.bind(this);
      }

      colDefs.push(colDef);
    });
    this.agGridOptions.api?.setColumnDefs(colDefs);
    this.agGridOptions.api?.setRowData(data);
  }

  protected gridOptions(): GridOptions<any> {
    return {
        domLayout: "autoHeight"
    };
  }
}
