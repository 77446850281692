import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets: string[] = ["fileUpload"];

  declare currentTab: string;
  declare readonly fileUploadTarget: HTMLElement;

  connect() {
    const form = this.element.closest("form");
    const readonly = (<HTMLElement>this.element).dataset.readonly === "true";

    form?.addEventListener("submit", (e: Event) => {
      if (readonly) { return true; }

      if (this.currentTab === "usePrevious") {
        // We're on the use previous tab, so we'll submit normally
        return true;
      } else {
        // We're on the file uploader, so we want to just let dropzone
        // handle everything
        e.preventDefault();
        e.stopPropagation();

        const fileUpload = this.fileUploadTarget;

        this.dispatch("upload", {
          target: fileUpload,
          detail: {},
        });
      }
    });
  }

  tabChanged(e: CustomEvent): void {
    this.currentTab = e.detail.tab;
  }
}
