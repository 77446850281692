import NestedForm from "stimulus-rails-nested-form";

export default class extends NestedForm {
  static targets = ["placeholder", ...super.targets];

  declare itemCount: number;
  declare readonly placeholderTarget: HTMLElement;

  connect() {
    super.connect();

    this.itemCount = this.element.querySelectorAll(
      this.wrapperSelectorValue
    ).length;
    this.togglePlaceholder();
  }

  add(e: Event) {
    super.add(e);

    this.itemCount += 1;
    this.togglePlaceholder();
  }

  remove(e: Event): void {
    super.remove(e);

    this.itemCount -= 1;
    this.togglePlaceholder();
  }

  private togglePlaceholder(): void {
    if (this.itemCount === 0) {
      this.placeholderTarget.classList.remove("hidden");
    } else {
      this.placeholderTarget.classList.add("hidden");
    }
  }
}
