import BasicDatatableController from "./basic_datatable_controller";
import CurrencyCellRenderer from "@/components/cell_renderers/currency_cell_renderer";
import {
  ColDef,
  GridOptions,
  ICellRendererParams,
  RowClassParams,
} from "ag-grid-community";
import CommentsCellRenderer from "@/components/cell_renderers/comments_cell_renderer";
import { MonthlySummaryLine } from "types";

const TOTAL_ROWS = new Set(["gross development cost", "total funding"]);
export default class extends BasicDatatableController {
  columnDefs(): ColDef[] {
    return [
      {
        field: "attributes.element",
        headerName: "Element",
        flex: 1,
        pinned: "left",
        cellClass: ["emphasise"],
      },
      {
        field: "attributes.underwrittenBudget",
        headerName: "Underwritten budget",
        cellRenderer: CurrencyCellRenderer,
      },
      {
        field: "attributes.variance",
        headerName: "Variance",
        cellRenderer: CurrencyCellRenderer,
      },
      {
        field: "attributes.forecastAgainstUnderwritten",
        headerName: "Forecast against underwritten",
        cellRenderer: CurrencyCellRenderer,
      },
      {
        field: "attributes.budgetMovementInPeriod",
        headerName: "Budget movement in period",
        cellRenderer: CurrencyCellRenderer,
      },
      {
        field: "attributes.expenditureToDate",
        headerName: "Expenditure to date",
        cellRenderer: CurrencyCellRenderer,
      },
      {
        field: "attributes.costToComplete",
        headerName: "Cost to complete",
        cellRenderer: CurrencyCellRenderer,
      },
      {
        field: "attributes.thisDrawdown",
        headerName: "This drawdown",
        cellRenderer: CurrencyCellRenderer,
      },
      {
        field: "attributes.commentsCount",
        headerName: "",
        pinned: "right",
        width: 75,
        cellRenderer: CommentsCellRenderer,
        cellRendererParams(params: ICellRendererParams) {
          const line = params.data as MonthlySummaryLine;

          return {
            resourceType: "monthly_summary_lines",
            resourceId: line.id,
          };
        },
      },
    ];
  }

  protected gridOptions(): GridOptions<any> {
    return {
      defaultColDef: { resizable: true },
      getRowClass(params: RowClassParams) {
        return params.data.attributes.element.toLowerCase() == "senior debt loan facility"
          ? "bg-yellow-300 p-1"
          : "";
      },
      rowHeight: 42,
    };
  }
}
