import { Controller } from "@hotwired/stimulus";
import Swiper, { Navigation, Pagination } from "swiper";

export default class extends Controller {
  static targets = ["map"];

  declare swiper: Swiper;

  connect(): void {
    this.swiper = new Swiper(this.element as HTMLElement, {
      modules: [Navigation, Pagination],
      direction: "horizontal",
      loop: true,
      pagination: {
        el: ".swiper-pagination"
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
    })
  }

  disconnect(): void {
    this.swiper.destroy()
  }
}
