import { formatDate } from "@/utils/dates";
import { GanttTask } from "types";

export enum DateColumn {
  StartDate = "start_date",
  EndDate = "end_date"
}

type GanttDateFormatter = (task: GanttTask) => string;

/**
 * Generates the html for displaying a tasks
 * date correctly in the gantt data grid
 */
export default (dateColumn: DateColumn) : GanttDateFormatter => {
  return (task: GanttTask) => {

    const date = task[dateColumn];
    return formatDate(date);
  };
};
