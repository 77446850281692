import BasicDatatableController from "./basic_datatable_controller";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import EditActionCellRenderer from "@/components/cell_renderers/edit_action_cell_renderer";
import {  Contract, ContractDetail, JSONApiResponseObject } from "types";
import CommentsCellRenderer from "@/components/cell_renderers/comments_cell_renderer";
import HtmlCellRenderer from "@/components/cell_renderers/html_cell_renderer";
import CurrencyCellRenderer from "@/components/cell_renderers/currency_cell_renderer";
import { formatDate } from "@/utils/dates";

const cellRendererParamsFn = (params: ICellRendererParams) => {
  const line = params.data as Contract;

  return {
    resourceType: "contract_details",
    resourceId: line.id,
  };
};

export default class extends BasicDatatableController {
  columnDefs(): ColDef[] {
    return [
      ...this.contractDetailColumns(),
      ...(
        this.readonly
          ? []
          : [
            {
              pinned: "right",
              width: 50,
              cellRenderer: EditActionCellRenderer,
              cellRendererParams: cellRendererParamsFn,
              resizable: false
            }
          ] as ColDef[]
      ),
      {
        cellRenderer: CommentsCellRenderer,
        field: "attributes.commentsCount",
        headerName: "",
        pinned: "right",
        resizable: false,
        width: 50,
        cellRendererParams: cellRendererParamsFn,
      },
    ];
  }

  public detailAdded(e: CustomEvent): void {
    const response = e.detail as JSONApiResponseObject<ContractDetail>;
    const addedRow = response.data;

    this.addRows(addedRow);
  }

  public detailUpdated(e: CustomEvent): void {
    const response = e.detail as JSONApiResponseObject<ContractDetail>;
    const updatedRow = response.data;

    this.updateRows(updatedRow);
  }

  private contractDetailColumns(): ColDef[] {
    const contractType = (<HTMLElement>this.element).dataset.contractType;
    console.log(contractType);

    if (contractType === "s106" || contractType === "s278" || contractType === "cil") {
      return this.detailedColumns(contractType);
    } else {
      return this.otherColumns();
    }
  }

  private otherColumns(): ColDef[] {
    return [
      { field: "attributes.contractDetail", headerName: "Contract Detail" },
      {
        field: "attributes.supplementaryInformation",
        headerName: "Supplementary Information",
        flex: 1,
        cellRenderer: HtmlCellRenderer
      },
      {
        field: "attributes.status",
        headerName: "Status",
        flex: 1
      }
    ];
  }

  private detailedColumns(contractType: string): ColDef[] {
    return [
      ...(contractType === "cil" 
        ? [] 
        : [
          { field: "attributes.contractDetail", headerName: "Obligations", flex: 1 },
          {
            field: "attributes.reference",
            headerName: "Reference",
            cellRenderer: HtmlCellRenderer
          }
        ]
      ),
      {
        field: "attributes.financialValue",
        headerName: "Financial value",
        cellRenderer: CurrencyCellRenderer 
      },
      {
        field: "attributes.byWhen",
        headerName: "By when",
        cellRenderer(params: ICellRendererParams) {
          return formatDate(params.value);
        }
      },
      {
        field: "attributes.status",
        headerName: "Status"
      }
    ];
  }
}
