import InsurancePoliciesBaseDatatableController from "./insurance_policies_base_datatable_controller";
import { ColDef, GridOptions, ICellRendererParams } from "ag-grid-community";
import CommentsCellRenderer from "@/components/cell_renderers/comments_cell_renderer";
import { InsurancePolicy } from "types";
import CurrencyCellRenderer from "@/components/cell_renderers/currency_cell_renderer";
import UploadsCellRenderer from "@/components/cell_renderers/uploads_cell_renderer";
import EditActionCellRenderer from "@/components/cell_renderers/edit_action_cell_renderer";
import DeleteActionCellRenderer from "@/components/cell_renderers/delete_action_cell_renderer";

const cellRendererParamsFn = (params: ICellRendererParams) => {
  const line = params.data as InsurancePolicy;

  return {
    resourceType: "insurance_policies",
    resourceId: line.id,
    uploadType: "insurance_policy"
  };
};

export default class extends InsurancePoliciesBaseDatatableController {
  columnDefs(): ColDef[] {
    return [
      { field: "attributes.professional", headerName: "Professional" },
      { field: "attributes.service", headerName: "Service" },
      {
        field: "attributes.value",
        headerName: "Value",
        cellRenderer: CurrencyCellRenderer,
      },
      { field: "attributes.basis", headerName: "Basis" },
      { field: "attributes.expiryDate", headerName: "Expiry date" },
      {
        field: "attributes.renewalDetails",
        headerName: "Renewal / Details required within 1 month",
      },
      ...(
        this.readonly
          ? []
          : [
            {
              pinned: "right",
              width: 50,
              cellRenderer: EditActionCellRenderer,
              cellRendererParams: cellRendererParamsFn,
              resizable: false
            },
            {
              field: "attributes.uploadsCount",
              headerName: "",
              pinned: "right",
              width: 50,
              cellRenderer: UploadsCellRenderer,
              cellRendererParams: cellRendererParamsFn,
              resizable: false
            }
          ] as ColDef[]
      ),
      {
        field: "attributes.commentsCount",
        headerName: "",
        pinned: "right",
        width: 75,
        cellRenderer: CommentsCellRenderer,
        cellRendererParams: cellRendererParamsFn,
        resizable: false
      },
      ...(
        this.readonly ? [] : [
          {
            pinned: "right",
            width: 50,
            cellRenderer: DeleteActionCellRenderer,
            cellRendererParams: cellRendererParamsFn,
            resizable: false
          },
        ] as ColDef[]
      ),
    ];
  }
}
