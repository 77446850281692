import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["slideout"];

  declare readonly slideoutTarget: HTMLElement;

  connect(): void {
    // Automatically show the moment the element connects.
    // Slideouts are rendered by a server response so we
    // want to show them immediately
    enter(this.slideoutTarget);
  }

  async dismiss(): Promise<void> {
    await leave(this.slideoutTarget);
    this.element.remove();
  }

  async dismissOnSuccess(e: CustomEvent): Promise<void> {
    if (e.detail.success) {
      await this.dismiss();
    }
  }
}
