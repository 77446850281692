import { compareAsc, format, parseISO } from "date-fns";

/**
 * Default system date format. All dates will be formatted
 * using this format. Date times will also use this format,
 * and append the time.
 */
const DATE_FORMAT = "dd LLL yyyy";

/**
 * Default system datetime format. All datetimes will be
 * formatted using this format.
 */
const DATETIME_FORMAT = `${DATE_FORMAT}, HH:mm`;

/**
 * A comparison function that can be used to sort a list
 * of dates. This function will ensure the dates are parsed
 * and compared correctly 
 **/ 
export const dateComparator = (date1: string | Date, date2: string | Date) => {
  const compareDate1 = processDate(date1);
  const compareDate2 = processDate(date2);

  return compareAsc(compareDate1, compareDate2);
}

/**
 * Formats the given date in the system default date
 * format. See the DATE_FORMAT const for more details
 * on this format.
 *
 * Input date can be either a string containing an ISO
 * formatted date, or a date object.
 *
 * @param date The date or date string to format
 * @returns The formatted date string
 */
export const formatDate = (date: string | Date | null): string => {
  if (!date) return "";

  const inputDate = processDate(date);
  return format(inputDate, DATE_FORMAT);
};

/**
 * Formats the given date in the system default datetime
 * format. See the DATETIME_FORMAT const for more details
 * on this format.
 *
 * Input date can be either a string containing an ISO
 * formatted date, or a date object.
 *
 * @param date The date or date string to format
 * @returns The formatted datetime string
 */
export const formatDatetime = (date: string | Date | null): string => {
  if (!date) return "";

  const inputDate = processDate(date);
  return format(inputDate, DATETIME_FORMAT);
};

/**
 * Parses an ISO date string into a Date object, or returns
 * the given Date object.
 *
 * @param date The date object or ISO string
 * @returns The processed date object
 */
const processDate = (date: string | Date): Date => {
  if (date instanceof Date) {
    return date;
  }

  return parseISO(date);
};
