export default {
  css: "gantt_container",
  cols: [
    {
      min_width: 200,
      width: 400,
      rows: [
        {
          view: "grid",
          scrollX: "gridScroll",
          scrollable: true,
          scrollY: "scrollVer",
        },
        { view: "scrollbar", id: "gridScroll" },
      ],
    },
    { resizer: true, width: 1 },
    {
      rows: [
        { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
        { view: "scrollbar", id: "scrollHor" },
      ],
    },
    { view: "scrollbar", id: "scrollVer" },
  ],
};
