import { ICellRendererComp, ICellRendererParams } from "ag-grid-community";

const COLOUR_CLASSES: { [index: string]: string[] } = {
  "RED": ["bg-red-50", "text-red-700", "ring-red-600/10"],
  "AMBER": ["bg-yellow-50", "text-yellow-800", "ring-yellow-600/20"],
  "GREEN": ["bg-green-50", "text-green-700", "ring-green-600/20"],
  "": ["bg-gray-50", "text-gray-600", "ring-gray-500/10"]
};

export default class RAGStatusCellRenderer implements ICellRendererComp {
  declare gui: HTMLElement;
  declare params: any;

  init(params: any) {
    this.params = params;
    this.gui = this.createLabel();
  }

  private createLabel(): HTMLElement {
    const label = document.createElement("span");
    label.classList.add("inline-flex", "items-center", "rounded-md", "px-2", "py-1", "text-base", "font-medium", "ring-1", "ring-inset", "capitalize");
    label.classList.add(...this.colourClasses());
    label.innerText = this.params.value || "N/A";

    if (this.params.url) {
      const anchor = document.createElement("a");
      anchor.href = this.params.url;
      anchor.dataset.turbo = "false";
      anchor.appendChild(label);

      return anchor;
    }

    return label;
  }

  private colourClasses(): string[] {
    const status = this.params.value || "" as string;
    return COLOUR_CLASSES[status.toUpperCase()];
  }

  public refresh() {
    return true;
  }

  public getGui() {
    return this.gui;
  }
}
