import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  connect(): void {
    this.element.addEventListener("turbo:submit-end", (e: Event) => {
      const evt = e as CustomEvent;
      const form = this.element as HTMLFormElement;
      const value = form.querySelector('input[name="comment[body]"]').value.trim();

      if (evt.detail.success && value !== '') {
        // Increment the count of comments for this record
        this.dispatch("created", {
          target: undefined,
          detail: {
            resourceId: form.dataset.resourceId,
            resourceType: form.dataset.resourceType,
          },
        });
      }
    });
  }
}
